<template>
    
    <div class="myBody">
    <div :style="{
        width: '100%',
        alignItems: 'center',
    }">
    <div class="title">Chose wich media you want to answer first :</div>
    <div v-if="existing[0]" :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">

        <MediaBanner :bannerInfo="{name: name[0], image: image[0], type: 'insta', isMobile: isMobile, check: isClicked0}" @input="getEmiter" @clicked="clicked"/>


    </div>

    <div v-if="existing[1]" :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">

        <MediaBanner :bannerInfo="{name: name[1], image: image[1], type: 'youtube', isMobile: isMobile, check: isClicked1}" @input="getEmiter" @clicked="clicked"/>

    </div>

    <div v-if="existing[2]" :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">

        <MediaBanner :bannerInfo="{name: name[2], image: image[2], type: 'tiktok', isMobile: isMobile, check: isClicked2}" @input="getEmiter" @clicked="clicked"/>


    </div>

    </div>
    

        
    <div v-if="existing[count]" :style="{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}">
        <div class="qst">
            discription :
            <div :style="{
                fontFamily: 'Josefin Sans, sans-serif', 
                fontWeight: 'bold', 
                fontSize: '14px'
    
            }">
                {{ discription[count] }}.
            </div>
        </div>

        

        <div class="qst">
            Question :
            <div :style="{
                fontFamily: 'Josefin Sans, sans-serif', 
                fontWeight: 'bold', 
                fontSize: '14px'
    
            }">
                {{ qst[count] }} ?
            </div>
        </div>

        

        <div class="answer hoverable" @click="select(answer1[count])">
            Answer 1 :
            <div :style="{
                fontFamily: 'Josefin Sans, sans-serif', 
                    fontWeight: 'bold', 
                    fontSize: '14px'
    
            }">
                {{ answer1[count] }}.
            </div>
        </div>

        

        <div class="answer hoverable" @click="select(answer2[count])">
            Answer 2 :
            <div :style="{
                fontFamily: 'Josefin Sans, sans-serif', 
                        fontWeight: 'bold', 
                        fontSize: '14px'
    
            }">
                {{ answer2[count] }}.
            </div>
        </div>

        

        <div class="answer hoverable" @click="select(answer3[count])">
            Answer 3 :
            <div :style="{
                fontFamily: 'Josefin Sans, sans-serif', 
                            fontWeight: 'bold', 
                            fontSize: '14px'
            }">
                {{ answer3[count] }}.
            </div>
        </div>

        <button v-if="selected" @click="validateQst(selected)">

            Are u sure you want to answer : {{ selected }}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#00ffe5" fill="none">
                <path d="M12.5 18C12.5 18 18.5 13.5811 18.5 12C18.5 10.4188 12.5 6 12.5 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.50005 18C5.50005 18 11.5 13.5811 11.5 12C11.5 10.4188 5.5 6 5.5 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Yes sur.
        </button>


        <div v-if="!responde && responded" class="resLine">
            <div class="boxLine" @click="responding('like', 1, media)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30" color="#00ffe5" fill="none">
                    <path d="M10.605 9.02776L6.20258 4.61605C5.61005 4.02227 5.61539 3.05421 6.21452 2.45382C6.81365 1.85343 7.77968 1.84807 8.37221 2.44185L16.1578 10.1671C18.8161 12.831 20.3484 16.3229 17.3811 19.9313L17.0646 20.2485C14.7342 22.5838 10.9558 22.5838 8.62533 20.2485L5.4366 17.0986C4.84407 16.5048 4.85617 15.53 5.46364 14.9213C6.03949 14.3442 6.94364 14.3033 7.54094 14.8064M10.605 9.02776L13.1141 11.5421M10.605 9.02776C10.0125 8.43398 9.01419 8.44781 8.40407 9.05922C7.79394 9.67062 7.77969 10.6476 8.37222 11.2414L9.10007 11.9708M9.10007 11.9708L10.8838 13.7583M9.10007 11.9708C8.50753 11.377 7.55767 11.4047 6.94755 12.0161C6.33742 12.6275 6.32317 13.6045 6.9157 14.1983L7.54094 14.8064M7.54094 14.8064L8.67132 15.9057M7.54094 14.8064C7.57373 14.834 7.6056 14.8633 7.63644 14.8942M12.1361 6.17661C12.3035 4.63978 14.1236 3.04666 16.3963 4.13842C16.6121 4.2421 16.7831 4.43824 16.7851 4.67806C16.7882 5.06439 16.6186 5.54136 16.075 5.85762C15.2974 6.31003 14.0236 8.03862 15.724 9.7426" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>
                    I like it
                </p>
            </div>
            <div class="boxLine" @click="responding('hate', 1, media)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30" color="#00ffe5" fill="none">
                    <path d="M6.6 13.7821V11.5M6.6 13.7821C6.6 14.7644 5.79411 15.5607 4.8 15.5607C3.80589 15.5607 3 14.7644 3 13.7821V13C3 9.25027 3 7.3754 3.95491 6.06107C4.26331 5.6366 4.6366 5.26331 5.06107 4.95491C6.3754 4 8.25027 4 12 4C15.7497 4 17.6246 4 18.9389 4.95491C19.3634 5.26331 19.7367 5.6366 20.0451 6.06107C21 7.3754 21 9.26015 21 13.0296C21 14.5561 21 15.3193 20.8431 15.9483C20.3627 17.8739 18.8411 19.3774 16.8923 19.8521C16.4854 19.9512 15.7827 19.9869 14.9252 19.9998C14.0788 19.9998 13.35 20.0759 13.35 18.9998C13.35 17.9839 13.6769 16.8946 14.925 16.8946H15.6M6.6 13.7821V15.116C6.6 16.0983 7.40589 16.8946 8.4 16.8946C9.39411 16.8946 10.2 16.0983 10.2 15.116M10.2 15.116V11.1143M10.2 15.116V15.9123C10.2 16.8946 11.0059 17.6909 12 17.6909C12.9941 17.6909 13.8 16.8946 13.8 15.9123V15.116M17.4 11.5V15.116C17.4 16.0983 16.5941 16.8946 15.6 16.8946M13.8 15.116V11.1143M13.8 15.116C13.8 16.0983 14.6059 16.8946 15.6 16.8946" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>
                    I hate it
                </p>
            </div>
            <div class="boxLine" @click="responding('notFound', 1, media)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30" color="#00ffe5" fill="none">
                    <path d="M2 2L22 22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M19.8478 14.9369C20 14.5694 20 14.1606 20 13.3431V10C20 6.22876 20 4.34315 18.8284 3.17157C17.6569 2 15.7712 2 12 2C9.33333 2 7.60948 2 6.41421 2.41421M4.41421 4.41421C4 5.60948 4 7.33333 4 10L4 14.5442C4 17.7892 4 19.4117 4.88607 20.5107C5.06508 20.7327 5.26731 20.9349 5.48933 21.1139C6.58831 22 8.21082 22 11.4558 22C12.1614 22 12.5141 22 12.8372 21.886C12.9044 21.8623 12.9702 21.835 13.0345 21.8043C13.3436 21.6564 13.593 21.407 14.0919 20.9081L17.5 17.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13 21.5L13 20.7487C13 17.9203 13 16.5061 13.8787 15.6274C14.1785 15.3276 14.5406 15.1301 15 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>
                    Not found
                </p>
            </div>
            <div class="boxLine" @click="responding('ilegal', 1, media)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#00ffe5" fill="none">
                    <path d="M10.5 11.25C10.5 10.2835 9.7165 9.5 8.75 9.5C7.7835 9.5 7 10.2835 7 11.25C7 12.2165 7.7835 13 8.75 13C9.7165 13 10.5 12.2165 10.5 11.25Z" stroke="currentColor" stroke-width="1.5" />
                    <path d="M17 11.25C17 10.2835 16.2165 9.5 15.25 9.5C14.2835 9.5 13.5 10.2835 13.5 11.25C13.5 12.2165 14.2835 13 15.25 13C16.2165 13 17 12.2165 17 11.25Z" stroke="currentColor" stroke-width="1.5" />
                    <path d="M11 16L11.2929 15.6306C11.6262 15.2102 11.7929 15 12 15C12.2071 15 12.3738 15.2102 12.7071 15.6306L13 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 2C7.30558 2 3.5 5.76644 3.5 10.4126C3.5 12.1664 4.04229 13.795 4.96997 15.1428C4.71662 15.4221 4.5625 15.7914 4.5625 16.1962C4.5625 17.0674 5.27605 17.7736 6.15625 17.7736V18.9929C6.15625 19.6422 6.46621 20.2619 7.02648 20.5901C10.2351 22.47 13.7649 22.47 16.9735 20.5901C17.5338 20.2619 17.8438 19.6422 17.8438 18.9929V17.7736C18.724 17.7736 19.4375 17.0674 19.4375 16.1962C19.4375 15.7914 19.2834 15.4221 19.03 15.1428C19.9577 13.795 20.5 12.1664 20.5 10.4126C20.5 5.76644 16.6944 2 12 2Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14 21.5V20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 21.5V20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>
                    Not legal
                </p>
            </div>
            <div class="boxLine" @click="responding('simple', 1, media)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#00ffe5" fill="none">
                    <path d="M7.41601 10L7.41601 13.5M7.41601 13.5V19.75C7.41601 20.7165 8.19951 21.5 9.16601 21.5C10.1325 21.5 10.916 20.7165 10.916 19.75V14.5L13.993 14.0224C15.9216 13.7331 16.886 13.5885 17.5652 13.1816C18.6872 12.5094 19.5 11.5 19.5 10.0264C19.5 9 19.2463 8.3114 18.6296 6.46127C18.2383 5.28733 18.0426 4.70036 17.7236 4.23571C17.1983 3.47073 16.4233 2.9122 15.5315 2.65576C14.9898 2.5 14.3711 2.5 13.1336 2.5H11.7287C9.94422 2.5 9.05198 2.5 8.28584 2.83495C7.9397 2.98627 7.61674 3.18587 7.32659 3.42779C6.68438 3.96326 6.28536 4.7613 5.48731 6.35738C4.84015 7.65171 4.51657 8.29887 4.50083 8.96984C4.49373 9.27244 4.53246 9.57436 4.61571 9.86537C4.80031 10.5106 5.27678 11.0552 6.2297 12.1442L7.41601 13.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>
                    Make it simple
                </p>
            </div>
            <div class="boxLine" @click="responding('harder', 1, media)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#00ffe5" fill="none">
                    <path d="M18.5 2.00195V8.00195M18.5 2.00195C17.7998 2.00195 16.4915 3.99625 16 4.50195M18.5 2.00195C19.2002 2.00195 20.5085 3.99625 21 4.50195" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.3913 21.998C15.3395 20.084 15.4684 19.8535 15.6052 19.4277C15.7419 19.002 16.6982 17.4665 17.0366 16.3695C18.1313 12.8202 17.111 12.0653 15.7507 11.0588C14.2422 9.94257 11.3968 9.37728 9.98573 9.49966V3.7462C9.98573 2.78288 9.20481 2.00195 8.24148 2.00195C7.27816 2.00195 6.49723 2.78288 6.49723 3.7462V9.96607M6.49774 21.9988V20.9854C6.43328 20.041 5.49529 18.9235 4.32672 17.3166C3.12509 15.576 2.86688 14.6973 3.05591 13.8848C3.15329 13.4694 3.40594 12.7832 4.64647 11.6104L6.49723 9.96607M6.49723 14.0323V9.96607" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>
                    Make it harder
                </p>
            </div>

        </div>

        

        

    </div>
    </div>
        

    <div v-if="isFinished" class="back-box">
        <div class="info-box">
            <div :style="{
                width: '100px',
                marginTop: '10px',
                minWidth: '100px',
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontFamily: 'Josefin Sans, sans-serif', 
                fontWeight: 'bold', 
                fontSize: '14px',
            }">
                {{ finishingMsg }}
            </div>

            <div :style="{
                width: '180px',
                marginTop: '10px',
                minWidth: '180px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Josefin Sans, sans-serif', 
                fontWeight: 'bold', 
                fontSize: '14px',
                cursor: 'pointer',
                border: '1px solid white'
                
            }" class="hoverable2" @click="isFinished = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ff5555" fill="none">
                <path d="M2 12.5C2 11.3954 2.89543 10.5 4 10.5C5.65685 10.5 7 11.8431 7 13.5V17.5C7 19.1569 5.65685 20.5 4 20.5C2.89543 20.5 2 19.6046 2 18.5V12.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.4787 7.80626L15.2124 8.66634C14.9942 9.37111 14.8851 9.72349 14.969 10.0018C15.0369 10.2269 15.1859 10.421 15.389 10.5487C15.64 10.7065 16.0197 10.7065 16.7791 10.7065H17.1831C19.7532 10.7065 21.0382 10.7065 21.6452 11.4673C21.7145 11.5542 21.7762 11.6467 21.8296 11.7437C22.2965 12.5921 21.7657 13.7351 20.704 16.0211C19.7297 18.1189 19.2425 19.1678 18.338 19.7852C18.2505 19.8449 18.1605 19.9013 18.0683 19.9541C17.116 20.5 15.9362 20.5 13.5764 20.5H13.0646C10.2057 20.5 8.77628 20.5 7.88814 19.6395C7 18.7789 7 17.3939 7 14.6239V13.6503C7 12.1946 7 11.4668 7.25834 10.8006C7.51668 10.1344 8.01135 9.58664 9.00069 8.49112L13.0921 3.96056C13.1947 3.84694 13.246 3.79012 13.2913 3.75075C13.7135 3.38328 14.3652 3.42464 14.7344 3.84235C14.774 3.8871 14.8172 3.94991 14.9036 4.07554C15.0388 4.27205 15.1064 4.37031 15.1654 4.46765C15.6928 5.33913 15.8524 6.37436 15.6108 7.35715C15.5838 7.46692 15.5488 7.5801 15.4787 7.80626Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            </div>

          
        </div>
      </div>

      <div :style="{height: '40px'}">

      </div>

</template>



<script>

import MediaBanner from '../components/module/mediaBanner.vue';


export default {
    metaInfo: {
    title: 'Instagrame Qst',
    
    meta: [
      { name: 'description', content: 'Answer Qst and earn money when you win' },
      { name: 'keywords', content: 'vue, SEO, web development, earn, money, gagner, argent, creator, createurs, contents, contenue, algorithme, answer' }
    ]
  },
    name: "InstaQst",
    components: {
        MediaBanner
    },
    data () {
        return {
            token: '',
            existing: [],
            image: [],
            name: [],
            swingiPoint: 0,
            qst: [],
            answer1: [],
            answer2: [],
            answer3: [],
            discription: [],
            country: [],
            active: [],
            count: -1,
            isFinished: false,
            finishingMsg: '',
            isManaging: false,
            isMobile: false,
            whatQst: '',
            isClicked0: false,
            isClicked1: false,
            isClicked2: false,
            test: false,
            responde: false,
            responded: false,
            selected: '',
            media: '',

        }
    },

    async mounted() {
        this.checkScreenSize();
    
        window.addEventListener('resize', this.checkScreenSize);

        this.token = this.$route.params.id;  // Récupérer le paramètre de l'URL
        await this.getQst('insta');
        await this.getQst('youtube');
        await this.getQst('tiktok');

    },

    beforeUnmount() {
        window.removeEventListener('resize', this.checkScreenSize);
    },

    methods: {
        clicked(value) {
            if(value === 'insta') {
                this.isClicked0 = !this.isClicked0;
                this.isClicked2 = false;
                this.isClicked1 = false;
                this.count = 0;
                this.media = 'insta';
            } else if (value === 'youtube') {
                this.isClicked1 = !this.isClicked1;
                this.isClicked2 = false;
                this.isClicked0 = false;
                this.count = 1;
                this.media = 'youtube';
            } else if (value === 'tiktok') {
                this.isClicked2 = !this.isClicked2;
                this.isClicked0 = false;
                this.isClicked1 = false;
                this.count = 2;
                this.media = 'tiktok';
            }
            console.log("this.media: ", this.media);
            this.selected = false;
            this.responde = false;
            this.responded = false;
        },
        getEmiter(value) {
            if(value === 'insta') {
                this.whatQst = 'insta';
            } else if (value === 'youtube') {
                this.whatQst = 'youtube';
            } else if (value === 'tiktok') {
                this.whatQst = 'tiktok';
            }

            
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth <= 768; // Détecte si l'écran est inférieur ou égal à 768px (taille mobile)
        },
        async validateQst(answer) {
            this.isManaging = true;
            var type;
            if(this.isClicked0) {
                type = 'insta';
                this.isClicked1 = false;
                this.isClicked2 = false;
            } else if (this.isClicked1) {
                type = 'youtube';
                this.isClicked0 = false;
                this.isClicked2 = false;
            } else if (this.isClicked2) {
                type = 'tiktok';
                this.isClicked1 = false;
                this.isClicked0 = false;
            }
            const userDetails = {
                token: localStorage.getItem('authToken'),
                tokenJob: this.token,
                answer: answer,
                type: type,
            }
            try {
                const response = await fetch('https://swingi.pro/api.php?action=jobManager', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(userDetails)
                })

                if (response.ok) {
                    
                    const data = await response.json();
                    console.log('data:', data);
                    
                    if (data.success) {
                        
                        this.finishingMsg = data.message;
                        this.isFinished = true;
                        this.responded = true;
                    } else {
                        
                        if (data.data) {
                            var finalTime = (data.data / 3600).toFixed(2);
                            if (finalTime >= 24) {
                                finalTime = (finalTime / 24).toFixed(0);
                                this.finishingMsg = `Pleas wait : ${finalTime} Day`;
                            } else if (finalTime > 1) {
                                this.finishingMsg = `Pleas wait : ${finalTime} hours`;
                            } else if (finalTime <= 1) {
                                finalTime = (finalTime * 60).toFixed(2);
                                this.finishingMsg = `Pleas wait : ${finalTime} min`;
                            } else if (finalTime >= 0) {
                                finalTime = (finalTime * 3600).toFixed(2);
                                this.finishingMsg = `Pleas wait : ${finalTime} sec`;
                            }
                            this.responded = false;
                            
                            
                        } else {
                            this.finishingMsg = data.message;
                            this.responded = true;
                        }
                        
                        this.isFinished = true;
                        
                        
                    }
                    
                } else {
                    
                    this.finishingMsg = response.error;
                    this.isFinished = true;
                    console.error('error: ',response);
                }
            } catch (e) {
                console.error('error fetch: ',e);
            }
                
            this.isManaging = false;
            
        },
        async getQst(value) {
            var tableQst;
            var tableUser;
            if (value === 'insta') {
                tableQst = 'instaQst';
                tableUser = 'insta';
            } else if (value === 'youtube') {
                tableQst = 'youtubeQst';
                tableUser = 'youtube';
            } else if (value === 'tiktok') {
                tableQst = 'tiktokQst';
                tableUser = 'tiktok';
            }
            try{
                const response = await fetch(`https://swingi.pro/api.php?action=getMediaQst&token=${encodeURIComponent(this.token)}&tableName=${tableQst}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                const response2 = await fetch(`https://swingi.pro/api.php?action=getUserMedia&token=${encodeURIComponent(this.token)}&tableName=${tableUser}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                const response3 = await fetch(`https://swingi.pro/api.php?action=getBankUser&token=${encodeURIComponent(this.token)}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.success) {
                        this.qst.push(data.data[2]);
                    this.discription.push(data.data[1]);
                    this.answer1.push(data.data[3]);
                    this.answer2.push(data.data[4]);
                    this.answer3.push(data.data[5]);
                    this.country.push(data.data[6]);
                    if (data.data[7] !== 1) {
                        this.active.push(false);
                    }
                    this.existing.push(true);
                    this.test = true;
                    } else {
                        this.test = false;
                    }
                    
                    
                    
                } else {
                    this.existing.push(false);
                    this.count = -1;
                }

                if (response2.ok) {
                    const data = await response2.json();
                    if (data.success) {
                        console.log('data suc', data.success);
                    if (value === 'insta') {
                        this.image.push(data.data[0].instaImage);
                        this.name.push(data.data[0].instaUser);
                    } else if (value === 'youtube') {
                        this.image.push(data.data[0].youtubeImage);
                        this.name.push(data.data[0].youtubeUser);
                    } else if (value === 'tiktok') {
                        this.image.push(data.data[0].tikTokImage);
                        this.name.push(data.data[0].tikTokUser);
                    }
                    
                    } else {
                        this.finishingMsg = data.message;
                        console.error(data.message);
                    }

                    if (response3.ok) {
                    const data = await response3.json();
                    this.swingiPoint = data.data.sPoint;
                }
                    

                } else {
                    console.error('error01', response2);
                }

                

            } catch (e) {
                console.error(e);
            }
        },

        select(value) {
            console.log('value', value);
            this.selected = value;
        },

        async responding (field, value, media) {
        try {
            const response = await fetch(`https://swingi.pro/api.php?action=updateAnalyticsQst&token=${encodeURIComponent(this.token)}&tableName=qstAnalytics&field=${field}&value=${value}&media=${media}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                if (!response.ok) {
                    console.error(response);
                    return
                }

                const data = await response.json();
                if (data.success) {
                    this.responde = true;
                    console.log(data.data);
                } else {
                    console.error(data.message);
                    return
                }

        }catch (e) {
            console.error(e);
        }

    }
    }


}



</script>


<style scoped>
.myBody {
    margin-left: 5%;
        width: 90%;
        min-width: 300px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-top: 100px;
        flex-direction: column;
        border-radius: 6px;
        background: rgba(242, 239, 239, 0.8);
        border: 1px solid rgba(255, 255, 255, .8);
        box-shadow: 0 0 10px #7fadc760;
        padding-bottom: 20px;
}

.dark .myBody {
    margin-left: 5%;
        width: 90%;
        min-width: 300px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-top: 100px;
        flex-direction: column;
        border-radius: 6px;
        background: #1c082d60;
    border: 1px solid rgba(20, 20, 20, 0.4);
    box-shadow: 0 0 10px #00000080;
    padding-bottom: 20px;
}
.info-box {
    position: fixed;
    width: 200px;
    min-width: 200px;
    padding: 20px;
    background: #f2efefcc;
    border: 2px solid rgba(255, 255, 255, .2);
    box-shadow: 0 0 10px #7fadc760;
    border-radius: 10px;
    left: 50%;
    top: 25%;
    transform: translate(-50%, 25%);
    z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    display: flex;
    flex-direction: column; /* Assure que les éléments sont alignés verticalement */
    align-items: center;
  
  }

  .dark .info-box {
    position: fixed;
    width: 200px;
    min-width: 200px;
    padding: 20px;
    background: #1c082dcc;
    border: 1px solid rgba(20, 20, 20, 0.4);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;
    left: 50%;
    top: 25%;
    transform: translate(-50%, 25%);
    z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    display: flex;
    flex-direction: column; /* Assure que les éléments sont alignés verticalement */
    align-items: center;
  
  }

  .resLine {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px; /* Ajoute un espacement uniforme entre les éléments */
    width: 80%; /* Prend toute la largeur disponible */
    margin: 15px 0;
    box-sizing: border-box; /* Inclut le padding dans les dimensions */
  }

  .boxLine {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    width: 40px;
    margin: 10px;
    padding-block: 2px;
    background: rgba(249, 248, 248, 0.8);
    border: 1px solid rgba(255, 255, 255, .8);
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 10px #7fadc760;
    
  }

  .dark .boxLine {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    width: 40px;
    margin: 10px;
    padding-block: 2px;
    background: #282859;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgba(20, 20, 20, 0.4);
    box-shadow: 0 0 10px #00000080;
  }

  .boxLine svg {
    height: 20px;
    width: 40px;
    color: #04041D;

  }

  .dark .boxLine svg {
    height: 20px;
    width: 40px;
    color: #d4d4e6;

  }

  .boxLine p {
    width: 40px;
    font-size: 1.5vh;
    font-family: Josefin Sans, sans-serif;

  }



  .back-box {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(90, 90, 90, 0.8);
    backdrop-filter: blur(2px);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */

    z-index: 50; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
  
  }

    .hoverable {
    transition: background 0.3s ease; /* Optionnel : pour une transition douce */
    }

    .hoverable:hover {
    background: #ccc;
    }

    .hoverable2 {
    transition: background 0.3s ease; /* Optionnel : pour une transition douce */
    }
  
  .hoverable2:hover {
    background: #bdff8e;
  }

  .dark .hoverable {
    transition: background 0.3s ease; /* Optionnel : pour une transition douce */
    }

    .dark .hoverable:hover {
    background: #0f1b22;
    }

    .dark .hoverable2 {
    transition: background 0.3s ease; /* Optionnel : pour une transition douce */
    }
  
  .dark .hoverable2:hover {
    background: #bdff8e;
  }

  .answer {
    width: 80%;
    min-width: 280px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    align-content: center;
    font-family: Josefin Sans, sans-serif;
    font-weight: bold;
    font-size: 11px;
    padding: 10px;
    border-radius: 6px;
    background: #7fadc740;
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 10px #7fadc760;
    cursor: pointer;
  }

  .dark .answer {
    width: 80%;
    min-width: 280px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    align-content: center;
    font-family: Josefin Sans, sans-serif;
    font-weight: bold;
    font-size: 11px;
    padding: 10px;
    border-radius: 6px;
    background: #7fadc740;
    border: 1px solid rgba(20, 20, 20, 0.4);
    box-shadow: 0 0 10px #00000080;
    cursor: pointer;
  }

  .qst {
    width: 80%;
    min-width: 280px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    align-content: center;
    font-family: Josefin Sans, sans-serif;
    font-weight: bold;
    font-size: 11px;
    padding: 10px;
    border-radius: 6px;
    background: #7fadc740;

  }

    button {
        width: 80%;
        min-width: 280px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Josefin Sans", sans-serif;
        font-weight: bold;
        font-size: 2.2vh;
        padding: 10px;
        border-radius: 6px;
        background: #7fadc740;
        border: 2px solid rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 10px #7fadc760;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: box-shadow 0.3s ease;
    }

    /* Mode sombre */
    .dark button {
        border: 2px solid rgba(20, 20, 20, 0.8);
        background: #28445440;
        box-shadow: 0 0 10px #00000080;
    }

    /* Animation pour changer la couleur du bord */
    button {
        animation: borderColorChange 3s infinite, borderGlow 3s infinite alternate;
    }

    /* Animation des couleurs de la bordure */
    @keyframes borderColorChange {
        0% {
            border-color: #ff0055;
        }
        25% {
            border-color: #ff7700;
        }
        50% {
            border-color: #77ff00;
        }
        75% {
            border-color: #0077ff;
        }
        100% {
            border-color: #ff0055;
        }
    }

    /* Animation du glow lumineux */
    @keyframes borderGlow {
        0% {
            box-shadow: 0 0 5px #ff0055;
        }
        25% {
            box-shadow: 0 0 5px #ff7700;
        }
        50% {
            box-shadow: 0 0 5px #77ff00;
        }
        75% {
            box-shadow: 0 0 5px #0077ff;
        }
        100% {
            box-shadow: 0 0 5px #ff0055;
        }
    }

  button svg {

    margin-left: 20px;
    margin-right: 5px;
    color: #04041D;

  }

  .dark button svg {

    margin-left: 20px;
    margin-right: 5px;
    color: #d4d4e6;

  }

  .dark .qst {
    width: 80%;
    min-width: 280px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    align-content: center;
    font-family: Josefin Sans, sans-serif;
    font-weight: bold;
    font-size: 11px;
    padding: 10px;
    border-radius: 6px;
    background: #7fadc740;

  }


</style>

