<template>

  <div v-if="localUsersInfo" class="sBox">
    <div class="pBox1">
      
        <div v-if="localUsersInfo[0]" class="box1" @click="instaClick(localUsersInfo[0].token)">
          <div :style="{display: 'flex', alignItems: 'center'}">
            <div class="pImage">
              <img class="shortImage" :src="localUsersInfo[0].image ? localUsersInfo[0].image: '/g.png'" alt="Thumbnail"/>
          </div>
          <div :class="{
                
            'text-xs': true,
            'max-w-250px': true,
            'overflow-hidden': true,
            'text-ellipsis': true,
            'whitespace-nowrap': true,
            }" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '12px', marginInline: '5px' }"
            >
            {{localUsersInfo[0].name ? localUsersInfo[0].name: 'no job'}}
          </div>
        </div>

        <div :style="{display:'flex', alignItems: 'center', fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '12px', marginInline: '5px', borderRadius: '15px'}" class="dark:bg-darkerBlue bg-white">
          <div :style="{marginInline: '5px', textAlign: 'center'}">
            {{ localUsersInfo[0].sp }}
          </div>
          <div :style="{marginInline: '5px', color: '#ff5555'}">
            <svg fill="#ff5555" width="14px" height="14px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
              <g id="SVGRepo_bgCarrier" stroke-width="0"/>
              
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
              
              <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
              
              </svg>
          </div>
        </div>

          </div>
          
      <div v-if="localUsersInfo[1]" class="box1" @click="instaClick(localUsersInfo[1].token)">
        <div :style="{display: 'flex', alignItems: 'center'}">
          <div class="pImage">
            <img class="shortImage" :src="localUsersInfo[1].image ? localUsersInfo[1].image: '/g.png'" alt="Thumbnail"/>
        </div>
        <div :class="{
              
          'text-xs': true,
          'max-w-250px': true,
          'overflow-hidden': true,
          'text-ellipsis': true,
          'whitespace-nowrap': true,
        }" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '12px', marginInline: '5px' }"
        >
          {{localUsersInfo[1].name ? localUsersInfo[1].name: 'no job'}}
        </div>
        </div>

        <div :style="{display:'flex', alignItems: 'center', fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '12px', marginInline: '5px', borderRadius: '15px'}" class="dark:bg-darkerBlue bg-white">
          <div :style="{marginInline: '5px', textAlign: 'center'}">
            {{ localUsersInfo[1].sp }}
          </div>
          <div :style="{marginInline: '5px', color: '#ff5555'}">
            <svg fill="#ff5555" width="14px" height="14px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
              <g id="SVGRepo_bgCarrier" stroke-width="0"/>
              
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
              
              <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
              
              </svg>
          </div>
          
          
        </div>
          
      </div>
    </div>
  <div class="pBox2">
    
      <div v-if="localUsersInfo[2]" class="box1" @click="instaClick(localUsersInfo[2].token)">
        <div :style="{display: 'flex', alignItems: 'center'}">
          <div class="pImage">
            <img class="shortImage" :src="localUsersInfo[2].image ? localUsersInfo[2].image: '/g.png'" alt="Thumbnail"/>
        </div>
        <div :class="{
              
          'text-xs': true,
          'max-w-250px': true,
          'overflow-hidden': true,
          'text-ellipsis': true,
          'whitespace-nowrap': true,
        }" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '12px', marginInline: '5px' }"
        >
          {{localUsersInfo[2].name ? localUsersInfo[2].name: 'no job'}}
        </div>
        </div>

        <div :style="{display:'flex', alignItems: 'center', fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '12px', marginInline: '5px', borderRadius: '15px'}" class="dark:bg-darkerBlue bg-white">
          <div :style="{marginInline: '5px', textAlign: 'center'}">
            {{ localUsersInfo[2].sp }}
          </div>
          <div :style="{marginInline: '5px', color: '#ff5555'}">
            <svg fill="#ff5555" width="14px" height="14px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
              <g id="SVGRepo_bgCarrier" stroke-width="0"/>
              
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
              
              <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
              
              </svg>
          </div>
          
          
        </div>
        
    </div>
    <div v-if="localUsersInfo[3]" class="box1" @click="instaClick(localUsersInfo[3].token)">
      <div :style="{display: 'flex', alignItems: 'center'}">
        <div class="pImage">
          <img class="shortImage" :src="localUsersInfo[3].image ? localUsersInfo[3].image: '/g.png'" alt="Thumbnail"/>
      </div>
      <div :class="{
            
        'text-xs': true,
        'max-w-250px': true,
        'overflow-hidden': true,
        'text-ellipsis': true,
        'whitespace-nowrap': true,
      }" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '12px', marginInline: '5px' }"
      
      >
        {{localUsersInfo[3].name ? localUsersInfo[3].name: 'no job'}}
      </div>
      </div>
      <div :style="{display:'flex', alignItems: 'center', fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '12px', marginInline: '5px', borderRadius: '15px'}" class="dark:bg-darkerBlue bg-white">
        <div :style="{marginInline: '5px', textAlign: 'center'}">
          {{ localUsersInfo[3].sp }}
        </div>
        <div :style="{marginInline: '5px', color: '#ff5555'}">
          <svg fill="#ff5555" width="14px" height="14px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
  
            <g id="SVGRepo_bgCarrier" stroke-width="0"/>
            
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
            
            <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
            
            </svg>
        </div>
        
        
      </div>
        
    </div>
    
      
      </div>
    
    </div>
    <div v-else-if="!localUsersInfo">
      <Loading type="submit" :width="'40px'"/>

    </div>

  </template>
  
  <script>
  import Loading from '../items/loading.vue';

  export default {
    name: "instaJob1",
    components: {
      Loading,
    },
    props: {
      usersInfo: {
        type: Array,
        required: false,
        validator(value) {
          // Vérifier que chaque élément du tableau est une chaîne de caractères
          return value.every(item => typeof item === 'string');
        }
    },

    
  },
    data() {
      return {
        localUsersInfo: [],
      };
    },

    created() {
      this.localUsersInfo = this.usersInfo;
    },
    methods: {
    
      instaClick (instaToken) {
        const foundIndex = this.localUsersInfo.findIndex(user => user.token === instaToken);
        if (foundIndex !== -1) {
          this.localUsersInfo.splice(foundIndex, 1); // Modification de la copie locale
        }
        this.$router.push({
          name: 'InstaQst',
          params: { id: instaToken }  // Transmettre les paramètres ici
        });
      }
  
  
    }
  };
  </script>
  
  <style scoped>

  .pBox1 {
    width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 20px;
 
    
  }
  .pBox2 {
    width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: right;
    margin: 20px;

    
  }

  .sBox {
    width: 100%;  
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline : 10%;
    

  }
  .box1 {
    width: 300px;
    height: 50px;
    min-width: 300px;
    background: #00A3FF60;
    border: 1px solid #e5f5ff80;
    backdrop-filter: blur(2px);
    margin-block: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .dark .box1 {
    width: 300px;
    height: 50px;
    min-width: 300px;
    background: #0c0c7c80;
    border: 1px solid #3c3cbc80;
    backdrop-filter: blur(2px);
    margin-block: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .pImage {
    width: 35px;
    height: 35px;
    min-width: 35px;
    max-height: 35px;
    background-color: white;
    border-radius: 50%;
    margin-inline: 5px;
  }

  .shortImage {
    width: 35px;
    height: 35px;
    min-width: 35px;
    max-height: 35px;
    border-radius: 50%;
    object-fit: cover;
  }

  </style>
  