<template>
    <div class="body">
      <div :style="{height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
        <div :style="{display: 'flex', alignItems: 'center', marginLeft: '5px'}">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ff6767" fill="none">
            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
          <p v-if="mediaName === 'insta'" class="instaTitle">
            Instagram
          </p>
          <p v-else-if="mediaName === 'tiktok'" class="tiktokTitle">
            Tiktok
          </p>
          <p v-else-if="mediaName === 'youtube'" class="YoutubeTitle">
            Youtube
          </p>
        </div>

        <div class="forSvg" :style="{display: 'flex', fontSize: '1.5vh', fontFamily: 'Josefin Sans, sans-serif', color: '#7fadc7', fontWeight: 'bold'}">
          <div :style="{
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginInline: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            alignItems: 'center',
            
            borderRadius: '5px',
          }"
          :class="{ hoverEffect: isHovered1 }"
            class="forBg"
            @mouseover="isHovered1 = true"
            @mouseleave="isHovered1 = false" @click="viewCard">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M14 3.5C17.7712 3.5 19.6569 3.5 20.8284 4.7448C22 5.98959 22 7.99306 22 12C22 16.0069 22 18.0104 20.8284 19.2552C19.6569 20.5 17.7712 20.5 14 20.5L10 20.5C6.22876 20.5 4.34315 20.5 3.17157 19.2552C2 18.0104 2 16.0069 2 12C2 7.99306 2 5.98959 3.17157 4.7448C4.34315 3.5 6.22876 3.5 10 3.5L14 3.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M5 15.5C6.60865 13.3626 10.3539 13.246 12 15.5M10.249 10.2501C10.249 11.2166 9.46552 12.0001 8.49902 12.0001C7.53253 12.0001 6.74902 11.2166 6.74902 10.2501C6.74902 9.28362 7.53253 8.50012 8.49902 8.50012C9.46552 8.50012 10.249 9.28362 10.249 10.2501Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 9.5L19 9.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 13.5H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          
          </div>
          
          
          <div :style="{
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginInline: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            alignItems: 'center',
            backgroundColor: '#ff5555',
            borderRadius: '5px',
          }"
          
            >
            <svg :style="{color: 'white'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
              <path d="M21 21H10C6.70017 21 5.05025 21 4.02513 19.9749C3 18.9497 3 17.2998 3 14V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 4H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 7H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M5 20C6.07093 18.053 7.52279 13.0189 10.3063 13.0189C12.2301 13.0189 12.7283 15.4717 14.6136 15.4717C17.8572 15.4717 17.387 10 21 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
          <div :style="{
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginInline: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            alignItems: 'center',
            
            borderRadius: '5px',
          }"
            :class="{ hoverEffect: isHovered2 }"
            class="forBg"
            @mouseover="isHovered2 = true"
            @mouseleave="isHovered2 = false" @click="changeInsta">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
            <div :style="{
              width: '30px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginInline: '5px',
              paddingInline: '10px',
              cursor: 'pointer',
              textAlign: 'center',
              alignItems: 'center',
              
              borderRadius: '5px',
            }"
              :class="{ hoverEffect: isHovered3 }"
              class="forBg"
              @mouseover="isHovered3 = true"
              @mouseleave="isHovered3 = false" @click="editInsta">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
                <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            </svg>
            
            </div>

            <div :style="{width: '30px', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginInline: '10px', paddingInline: '10px', cursor: 'pointer', textAlign: 'center', alignItems: 'center'}">
              <CheckBox label="" v-model="isChecked" @input="updateCheckValue(!isChecked, newMediaName)" :value="isChecked"/>
            
            </div>
            
            
            
            
        </div>
        
      </div>
      <div :style="{width: '100%',
      margin: '5px', 
      padding: '5px',
      borderRadius: '6px', 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      fontSize: '2vh'
    }">
        <div v-if="info.like" :style="{width: '100px',
        height: '100px', 
        margin: '2px', 
        borderRadius: '8px', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }"
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#ff5555" fill="none">
          <path d="M19.4626 3.99415C16.7809 2.34923 14.4404 3.01211 13.0344 4.06801C12.4578 4.50096 12.1696 4.71743 12 4.71743C11.8304 4.71743 11.5422 4.50096 10.9656 4.06801C9.55962 3.01211 7.21909 2.34923 4.53744 3.99415C1.01807 6.15294 0.221721 13.2749 8.33953 19.2834C9.88572 20.4278 10.6588 21 12 21C13.3412 21 14.1143 20.4278 15.6605 19.2834C23.7783 13.2749 22.9819 6.15294 19.4626 3.99415Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      </svg>
      <text>
        {{ info.like }}
      </text>
      Like
      
        </div>
  
        <div v-if="info.hate" :style="{width: '100px',
        height: '100px', 
        margin: '2px', 
        borderRadius: '8px', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }"
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#ff5555" fill="none">
          <path d="M2 11.5C2 12.6046 2.89543 13.5 4 13.5C5.65685 13.5 7 12.1569 7 10.5V6.5C7 4.84315 5.65685 3.5 4 3.5C2.89543 3.5 2 4.39543 2 5.5V11.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M15.4787 16.1937L15.2124 15.3337C14.9942 14.6289 14.8851 14.2765 14.969 13.9982C15.0369 13.7731 15.1859 13.579 15.389 13.4513C15.64 13.2935 16.0197 13.2935 16.7791 13.2935H17.1831C19.7532 13.2935 21.0382 13.2935 21.6452 12.5327C21.7145 12.4458 21.7762 12.3533 21.8296 12.2563C22.2965 11.4079 21.7657 10.2649 20.704 7.9789C19.7297 5.88111 19.2425 4.83222 18.338 4.21485C18.2505 4.15508 18.1605 4.0987 18.0683 4.04586C17.116 3.5 15.9362 3.5 13.5764 3.5H13.0646C10.2057 3.5 8.77628 3.5 7.88814 4.36053C7 5.22106 7 6.60607 7 9.37607V10.3497C7 11.8054 7 12.5332 7.25834 13.1994C7.51668 13.8656 8.01135 14.4134 9.00069 15.5089L13.0921 20.0394C13.1947 20.1531 13.246 20.2099 13.2913 20.2493C13.7135 20.6167 14.3652 20.5754 14.7344 20.1577C14.774 20.1129 14.8172 20.0501 14.9036 19.9245C15.0388 19.728 15.1064 19.6297 15.1654 19.5323C15.6928 18.6609 15.8524 17.6256 15.6108 16.6429C15.5838 16.5331 15.5488 16.4199 15.4787 16.1937Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <text>
        {{ info.hate }}
      </text>
          Hate
        </div>

        <div v-if="info.notFound" :style="{width: '100px',
        height: '100px', 
        margin: '2px', 
        borderRadius: '8px', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }"
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#ff5555" fill="none">
          <path d="M2 2L22 22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M19.8478 14.9369C20 14.5694 20 14.1606 20 13.3431V10C20 6.22876 20 4.34315 18.8284 3.17157C17.6569 2 15.7712 2 12 2C9.33333 2 7.60948 2 6.41421 2.41421M4.41421 4.41421C4 5.60948 4 7.33333 4 10L4 14.5442C4 17.7892 4 19.4117 4.88607 20.5107C5.06508 20.7327 5.26731 20.9349 5.48933 21.1139C6.58831 22 8.21082 22 11.4558 22C12.1614 22 12.5141 22 12.8372 21.886C12.9044 21.8623 12.9702 21.835 13.0345 21.8043C13.3436 21.6564 13.593 21.407 14.0919 20.9081L17.5 17.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13 21.5L13 20.7487C13 17.9203 13 16.5061 13.8787 15.6274C14.1785 15.3276 14.5406 15.1301 15 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <text>
        {{ info.notFound }}
      </text>
          Non-existent
        </div>
  
        <div v-if="info.ilegal" :style="{width: '100px',
        height: '100px', 
        margin: '2px', 
        borderRadius: '8px', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }"
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#ff5555" fill="none">
          <path d="M10.5 11.25C10.5 10.2835 9.7165 9.5 8.75 9.5C7.7835 9.5 7 10.2835 7 11.25C7 12.2165 7.7835 13 8.75 13C9.7165 13 10.5 12.2165 10.5 11.25Z" stroke="currentColor" stroke-width="1.5" />
          <path d="M17 11.25C17 10.2835 16.2165 9.5 15.25 9.5C14.2835 9.5 13.5 10.2835 13.5 11.25C13.5 12.2165 14.2835 13 15.25 13C16.2165 13 17 12.2165 17 11.25Z" stroke="currentColor" stroke-width="1.5" />
          <path d="M11 16L11.2929 15.6306C11.6262 15.2102 11.7929 15 12 15C12.2071 15 12.3738 15.2102 12.7071 15.6306L13 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 2C7.30558 2 3.5 5.76644 3.5 10.4126C3.5 12.1664 4.04229 13.795 4.96997 15.1428C4.71662 15.4221 4.5625 15.7914 4.5625 16.1962C4.5625 17.0674 5.27605 17.7736 6.15625 17.7736V18.9929C6.15625 19.6422 6.46621 20.2619 7.02648 20.5901C10.2351 22.47 13.7649 22.47 16.9735 20.5901C17.5338 20.2619 17.8438 19.6422 17.8438 18.9929V17.7736C18.724 17.7736 19.4375 17.0674 19.4375 16.1962C19.4375 15.7914 19.2834 15.4221 19.03 15.1428C19.9577 13.795 20.5 12.1664 20.5 10.4126C20.5 5.76644 16.6944 2 12 2Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14 21.5V20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10 21.5V20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <text>
        {{ info.ilegal }}
      </text>
          Sanity
        </div>

        <div v-if="info.simple" :style="{width: '100px',
        height: '100px', 
        margin: '2px', 
        borderRadius: '8px', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }"
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#ff5555" fill="none">
          <path d="M3.5 10.5V19.5C3.5 19.9659 3.5 20.1989 3.57612 20.3827C3.67761 20.6277 3.87229 20.8224 4.11732 20.9239C4.30109 21 4.53406 21 5 21C5.46594 21 5.69891 21 5.88268 20.9239C6.12771 20.8224 6.32239 20.6277 6.42388 20.3827C6.5 20.1989 6.5 19.9659 6.5 19.5V10.5C6.5 10.0341 6.5 9.80109 6.42388 9.61732C6.32239 9.37229 6.12771 9.17761 5.88268 9.07612C5.69891 9 5.46594 9 5 9C4.53406 9 4.30109 9 4.11732 9.07612C3.87229 9.17761 3.67761 9.37229 3.57612 9.61732C3.5 9.80109 3.5 10.0341 3.5 10.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
          <path d="M17.5 8L19.5 10.5L17 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M19.0001 10.502C19.0001 10.502 11.5001 10.502 4.00012 3.00195" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.5 14V19.5C10.5 19.9659 10.5 20.1989 10.5761 20.3827C10.6776 20.6277 10.8723 20.8224 11.1173 20.9239C11.3011 21 11.5341 21 12 21C12.4659 21 12.6989 21 12.8827 20.9239C13.1277 20.8224 13.3224 20.6277 13.4239 20.3827C13.5 20.1989 13.5 19.9659 13.5 19.5V14C13.5 13.5341 13.5 13.3011 13.4239 13.1173C13.3224 12.8723 13.1277 12.6776 12.8827 12.5761C12.6989 12.5 12.4659 12.5 12 12.5C11.5341 12.5 11.3011 12.5 11.1173 12.5761C10.8723 12.6776 10.6776 12.8723 10.5761 13.1173C10.5 13.3011 10.5 13.5341 10.5 14Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
          <path d="M17.5 16.5V19.5C17.5 19.9659 17.5 20.1989 17.5761 20.3827C17.6776 20.6277 17.8723 20.8224 18.1173 20.9239C18.3011 21 18.5341 21 19 21C19.4659 21 19.6989 21 19.8827 20.9239C20.1277 20.8224 20.3224 20.6277 20.4239 20.3827C20.5 20.1989 20.5 19.9659 20.5 19.5V16.5C20.5 16.0341 20.5 15.8011 20.4239 15.6173C20.3224 15.3723 20.1277 15.1776 19.8827 15.0761C19.6989 15 19.4659 15 19 15C18.5341 15 18.3011 15 18.1173 15.0761C17.8723 15.1776 17.6776 15.3723 17.5761 15.6173C17.5 15.8011 17.5 16.0341 17.5 16.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
      </svg>
      <text>
        {{ info.simple }}
      </text>
          Simplifies
        </div>
  
        <div v-if="info.harder" :style="{width: '100px',
        height: '100px', 
        margin: '2px', 
        borderRadius: '8px', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }"
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#ff5555" fill="none">
          <path d="M20.5 10.5V19.5C20.5 19.9659 20.5 20.1989 20.4239 20.3827C20.3224 20.6277 20.1277 20.8224 19.8827 20.9239C19.6989 21 19.4659 21 19 21C18.5341 21 18.3011 21 18.1173 20.9239C17.8723 20.8224 17.6776 20.6277 17.5761 20.3827C17.5 20.1989 17.5 19.9659 17.5 19.5V10.5C17.5 10.0341 17.5 9.80109 17.5761 9.61732C17.6776 9.37229 17.8723 9.17761 18.1173 9.07612C18.3011 9 18.5341 9 19 9C19.4659 9 19.6989 9 19.8827 9.07612C20.1277 9.17761 20.3224 9.37229 20.4239 9.61732C20.5 9.80109 20.5 10.0341 20.5 10.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
          <path d="M16.5 3H19.5V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M19 3.5C19 3.5 15 8.5 4.5 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13.5 14V19.5C13.5 19.9659 13.5 20.1989 13.4239 20.3827C13.3224 20.6277 13.1277 20.8224 12.8827 20.9239C12.6989 21 12.4659 21 12 21C11.5341 21 11.3011 21 11.1173 20.9239C10.8723 20.8224 10.6776 20.6277 10.5761 20.3827C10.5 20.1989 10.5 19.9659 10.5 19.5V14C10.5 13.5341 10.5 13.3011 10.5761 13.1173C10.6776 12.8723 10.8723 12.6776 11.1173 12.5761C11.3011 12.5 11.5341 12.5 12 12.5C12.4659 12.5 12.6989 12.5 12.8827 12.5761C13.1277 12.6776 13.3224 12.8723 13.4239 13.1173C13.5 13.3011 13.5 13.5341 13.5 14Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
          <path d="M6.5 16.5V19.5C6.5 19.9659 6.5 20.1989 6.42388 20.3827C6.32239 20.6277 6.12771 20.8224 5.88268 20.9239C5.69891 21 5.46594 21 5 21C4.53406 21 4.30109 21 4.11732 20.9239C3.87229 20.8224 3.67761 20.6277 3.57612 20.3827C3.5 20.1989 3.5 19.9659 3.5 19.5V16.5C3.5 16.0341 3.5 15.8011 3.57612 15.6173C3.67761 15.3723 3.87229 15.1776 4.11732 15.0761C4.30109 15 4.53406 15 5 15C5.46594 15 5.69891 15 5.88268 15.0761C6.12771 15.1776 6.32239 15.3723 6.42388 15.6173C6.5 15.8011 6.5 16.0341 6.5 16.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
      </svg>
      <text>
        {{ info.harder }}
      </text>
          Complicates it
        </div>
      </div>
      

    </div>
</template>
  
  <script>
  import CheckBox from "../CheckBox.vue";
  export default {
    name: "mediaAnalytics",
    props: {
        mediaName: {
          type: String,
          default: ""

      },
  
      
    },
    components: {
    CheckBox,
    
  },

    data() {
      return {
        mediaCard: '',
        mediaEdit: '',
        mediaChange: '',
        info: [],
        isChecked: false,
        token: localStorage.getItem('authToken'),
        qst: [],
        newMediaName: '',
      };
    },

    mounted() {
      if (this.mediaName === 'insta') {
        this.newMediaName = 'instaQst';
        this.mediaCard = 'instagram-card';
        this.mediaEdit = 'instagram-edit';
        this.mediaChange = 'instagram-change';
      } else if (this.mediaName === 'youtube') {
        this.newMediaName = 'youtubeQst';
        this.mediaCard = 'Youtube-card';
        this.mediaEdit = 'Youtube-edit';
        this.mediaChange = 'Youtube-change';
      } else if (this.mediaName === 'tiktok') {
        this.newMediaName = 'tiktokQst';
        this.mediaCard = 'tikTok-card';
        this.mediaEdit = 'tikTok-edit';
        this.mediaChange = 'tikTok-change';
      }

      this.getAnalytics();
      this.addInstaQst(this.newMediaName);
    },
    methods: {

      viewCard(){
        this.$emit(this.mediaCard);
      },

      editInsta(){
        this.$emit(this.mediaEdit);
      },

      changeInsta(){
        this.$emit(this.mediaChange);
      },

      async updateCheckValue(value, media) {
      
      this.isChecked = value;
      var checkBox = 0;
      if(this.isChecked === true) {
        checkBox = 1;
      } else {
        checkBox = 0;
      }
      const updateData = {
        token: this.token,
        field: 'activ',
        value: checkBox,
        tableName: media
      };

      var setItemName = '';
      if(media === 'instaQst') {
        setItemName = 'insta-Active';
      } else if(media === 'youtubeQst') {
        setItemName = 'Youtube-Active';
      } else if(media === 'tiktokQst') {
        setItemName = 'tiktok-Active';
      }


      try {
        const response = await fetch('https://swingi.pro/api.php?action=updateMediaQstValue', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        });
        const data = await response.json();
        if (data.success) {
          
          localStorage.setItem(setItemName, this.isChecked);
        } else {
          console.error('Error updating insta Value:', data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    async addInstaQst(media) {

      try {
        
        const response = await fetch(`https://swingi.pro/api.php?action=getMediaQst&token=${encodeURIComponent(this.token)}&tableName=${media}`, {
          method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.error) {
          console.error('Erreur:', data.error, 'Détails:', data.details);
        } else {
          this.qst = data.data;
          if (this.qst) {
            var setItemName = '';
            if(media === 'instaQst') {
              setItemName = 'insta-Active';
            } else if(media === 'youtubeQst') {
              setItemName = 'Youtube-Active';
            } else if(media === 'tiktokQst') {
              setItemName = 'tiktok-Active';
            }
            if (this.qst[7] == 0){
                localStorage.setItem(setItemName, false);
                
                this.isChecked = false;

              } else if (this.qst[7] == 1) {
                localStorage.setItem(setItemName, true);

                this.isChecked = true;

              }
          } else {
                localStorage.setItem(setItemName, false);
                
                this.isChecked = false;
              }

              
        }

        this.canGo = true;
      } catch (error) {
        console.error('Erreur lors de la requête POST:', error);
        this.info = { error: `Error: ${error.message}` };
      }
      },

      async getAnalytics() {
        const token = `${localStorage.getItem('authToken')};${this.mediaName}`;
        try {
        const response = await fetch(`https://swingi.pro/api.php?action=getMediaAnalytics&token=${token}`);
        if (response.ok) {
          const data = await response.json();
          
          if (data.success) {  
            console.log('data.data: ', data.data);
            this.info = data.data;
          } else {
            console.log('data.data: ', data.message);
            this.info = data.message;
          }
        } else {
          console.error('response: ', response);
          return
        }

        
      } catch (error) {
        console.error(`Error: ${error.message}`);
          return

        
      }
      }

    },
    };

  </script>
  
  <style scoped>

  text {
    font-size: 2.5vh;
    font-weight: bold;
  }
  /* Vous pouvez ajouter des styles supplémentaires ici */
  </style>
  