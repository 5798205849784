<template>
    <div class="menu">
        <EmailForm/>
    </div>
</template>

<script>
import EmailForm from "../components/EmailForm.vue";

export default {
name: "UserDetails",
  components: {
    EmailForm,

  }
  }
</script>

<style>

.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

</style>