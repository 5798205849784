<template>
  <menu >
    <li class="list-none h-20"></li>
    <div v-if="userId !== '0'" class="userCenter">
      <div v-if="!isAuthenticated">
        
        <a v-if="info[foundTokenIndex]">
          {{ info[foundTokenIndex].token }}  {{ info[foundTokenIndex].userName }}
        </a>
        <a v-else>
          <div class="loading-bar-follow"></div>
        </a>
      </div>
      <div v-else>
        
        

        <div class="parent">
          
          <UserCardInfo />
        </div>

        <div class="parent">
          <Refer :token="token" />
        </div>

        <div v-if="!instaUser" class="parent">
          <AddMedia 
          :label="'insta'"
          @insta="handleInstaEvent"
          @abortInstaBtn="isAddingInsta = false"
           />
        </div>
        <div class="parent" v-if="isAddingInsta">
          <AddInstagrame 
            
            @instagram-confirmed="isInstaConfirmed = true, isAddingInsta = false" />
        </div>
        
        
        <div v-if="!isInstaEdit && !isInstaChange && instaUser && !isAddingInsta && !isInstaAnalyse">
          <div class="parent" v-if="isInstaConfirmed && instaUser && !isInstaEdit && !isInstaChange">
            <InstagramCardInfo 
              @instagram-edit="isInstaEdit = true, isInstaChange = false"
              @instagram-change="isInstaEdit = false, isInstaChange = true"
              @instagram-stat="isInstaEdit = false, isInstaChange = false, isInstaAnalyse = true"
               />
          </div>
        </div>

        <div v-if="!isInstaEdit && !isInstaChange && instaUser && !isAddingInsta && isInstaAnalyse">
          <div class="parent">
            <MediaAnalytics :mediaName = "'insta'"
            @instagram-edit="isInstaEdit = true, isInstaChange = false, isInstaAnalyse = false"
              @instagram-change="isInstaEdit = false, isInstaChange = true, isInstaAnalyse = false"
              @instagram-card="isInstaAnalyse = false"
              
              />
          </div>
        </div>

        <div class="parent" v-if="isInstaEdit && !isInstaChange  && !isAddingInsta && !isInstaAnalyse">
          <InstagramEdit @cancel="canceling"
          @instagram-change="isInstaEdit = false, isInstaChange = true"
          @instagram-card="isInstaEdit = false, isInstaChange = false"
          @instagram-stat="isInstaEdit = false, isInstaChange = false, isInstaAnalyse = true"
          />
        </div>
        <div class="parent" v-if="!isInstaEdit && isInstaChange  && !isAddingInsta && !isInstaAnalyse">
          <AddInstagrame 
          @instagram-edit="isInstaEdit = true, isInstaChange = false"
          @instagram-card="isInstaEdit = false, isInstaChange = false"
          @instagram-stat="isInstaEdit = false, isInstaChange = false, isInstaAnalyse = true"
          @instagram-confirmed="isInstaConfirmed = true" />
        </div>

        
        <div v-if="!tikTokUser" class="parent">
          <AddMedia 
          :label="'tiktok'"
          @tiktok="handleTikTokEvent"
          @abortTikTokBtn="isAddingTikTok = false"
          
          />
        </div>
        <div class="parent" v-if="isAddingTikTok">
          <AddTikTok 
          @tikTok-confirmed="isTikTokConfirmed = true, isAddingTikTok = false" 
          
          />
          
        </div>

        <div v-if="!isTikTokEdit && !isTikTokChange && tikTokUser  && !isAddingTikTok && !isTikTokAnalyse">
          <div class="parent" v-if="isInstaConfirmed">
            <TikTokCardInfo 
              @tikTok-edit="isTikTokEdit = true, isTikTokChange = false"
              @tikTok-change="isTikTokEdit = false, isTikTokChange = true" 
              @tikTok-stat="isTikTokEdit = false, isTikTokChange = false, isTikTokAnalyse = true"/>
          </div>
        </div>

        <div v-if="!isInstaEdit && !isInstaChange && instaUser && !isAddingInsta && isTikTokAnalyse">
          <div class="parent">
            <MediaAnalytics :mediaName = "'tiktok'"
              @tikTok-edit="isTikTokEdit = true, isTikTokChange = false, isTikTokAnalyse = false"
              @tikTok-change="isTikTokEdit = false, isTikTokChange = true, isTikTokAnalyse = false" 
              @tikTok-card="isTikTokAnalyse = false"
              
              />
          </div>
        </div>

        <div class="parent" v-if="isTikTokEdit && !isTikTokChange  && !isAddingTikTok && !isTikTokAnalyse">
          <TikTokEdit 
          @tikTok-change="isTikTokEdit = false, isTikTokChange = true"
          @tikTok-card="isTikTokEdit = false, isTikTokChange = false"
          @tikTok-stat="isTikTokEdit = false, isTikTokChange = false, isTikTokAnalyse = true"
          />
        </div>
        <div class="parent" v-if="!isTikTokEdit && isTikTokChange  && !isAddingTikTok && !isTikTokAnalyse">
          <AddTikTok 
          @tikTok-edit="isTikTokEdit = true, isTikTokChange = false"
          @tikTok-card="isTikTokEdit = false, isTikTokChange = false"
          @tikTok-stat="isTikTokEdit = false, isTikTokChange = false, isTikTokAnalyse = true"
          @tikTok-confirmed="isTikTokConfirmed = true" />
          
        </div>


        <div v-if="!YoutubeUser" class="parent">
          <AddMedia 
          :label="'youtube'"
          @youtube="handleYoutubeEvent"
          @abortYoutubeBtn="isAddingYoutube = false"
          />
        </div>

        <div class="parent" v-if="isAddingYoutube">
          <AddYoutube @Youtube-confirmed="isYoutubeConfirmed = true, isAddingYoutube = false" />
        </div>

        <div v-if="!isYoutubeEdit && !isYoutubeChange && YoutubeUser && !isAddingYoutube && !isYoutubeAnalyse">
          <div class="parent">
            <YoutubeCardInfo 
              @Youtube-edit="isYoutubeEdit = true, isYoutubeChange = false"
              @Youtube-change="isYoutubeEdit = false, isYoutubeChange = true" 
              @Youtube-stat="isYoutubeEdit = false, isYoutubeChange = false, isYoutubeAnalyse = true"/>
          </div>
        </div>

        <div v-if="!isInstaEdit && !isInstaChange && instaUser && !isAddingInsta && isYoutubeAnalyse">
          <div class="parent">
            <MediaAnalytics :mediaName = "'youtube'"
            @Youtube-edit="isYoutubeEdit = true, isYoutubeChange = false, isYoutubeAnalyse = false"
              @Youtube-change="isYoutubeEdit = false, isYoutubeChange = true, isYoutubeAnalyse = false" 
              @Youtube-card="isYoutubeAnalyse = false"
              
              />
          </div>
        </div>

        <div class="parent" v-if="isYoutubeEdit && !isYoutubeChange && !isAddingYoutube && !isYoutubeAnalyse">
          <YoutubeEdit
          @Youtube-change="isYoutubeEdit = false, isYoutubeChange = true"
          @Youtube-card="isYoutubeEdit = false, isYoutubeChange = false"
          @Youtube-stat="isYoutubeEdit = false, isYoutubeChange = false, isYoutubeAnalyse = true"
          />
        </div>
        <div class="parent" v-if="!isYoutubeEdit && isYoutubeChange && !isAddingYoutube && !isYoutubeAnalyse">
          <AddYoutube 
          @Youtube-edit="isYoutubeEdit = true, isYoutubeChange = false"
          @Youtube-card="isYoutubeEdit = false, isYoutubeChange = false"
          @Youtube-stat="isYoutubeEdit = false, isYoutubeChange = false, isYoutubeAnalyse = true"
          @Youtube-confirmed="isYoutubeConfirmed = true" />
        </div>


      </div>
    </div>
    <div v-else class="transition-wrapper">
      <transition name="fade" mode="out-in">
        <component :is="currentComponent" @register-click="changeModeToLogin" @login-click="changeModeToSignIn"></component>
      </transition>
    </div>
    <div class="white-space">

    </div>
  </menu>

</template>

<script>
import LoginCard from "../components/LoginCard.vue";
import SignCard from "../components/SignCard.vue";
import UserCardInfo from "../components/UserInformation.vue";
import InstagramCardInfo from "../components/InstagramCardInfo.vue";
import InstagramEdit from "../components/EditInstagram.vue";
import AddInstagrame from "../components/AddInstagrame.vue";
import TikTokCardInfo from "../components/TikTokCardInfo.vue";
import TikTokEdit from "../components/EditTikTok.vue";
import AddTikTok from "../components/AddTikTok.vue";
import YoutubeCardInfo from "../components/YoutubeCardInfo.vue";
import YoutubeEdit from "../components/EditYoutube.vue";
import AddYoutube from "../components/AddYoutube.vue";
import Refer from "../components/Refer&Earn.vue";
import AddMedia from "../components/constant/addMedia.vue";
import MediaAnalytics from "../components/constant/MediaAnalytics.vue";

export default {
  metaInfo: {
    title: 'User Details',
    meta: [
      { name: 'description', content: 'Manage your account' },
      { name: 'keywords', content: 'vue, SEO, web development, earn, money, gagner, argent, creator, createurs, contents, contenue, algorithme, answer, swingi, point, manage, account' }
    ]
  },


  name: "UserDetails",
  components: {
    LoginCard,
    SignCard,
    UserCardInfo,
    InstagramCardInfo,
    AddInstagrame,
    TikTokCardInfo,
    TikTokEdit,
    AddTikTok,
    InstagramEdit,
    YoutubeCardInfo,
    YoutubeEdit,
    AddYoutube,
    Refer,
    AddMedia,
    MediaAnalytics,
  },
  data() {
    return {
      username: "",
      password: "",
      mode: 0,
      profileName: "",
      phoneNumber: "",
      isAuthenticated: false,
      token: "",
      info: [],
      info2: [],
      info3: [],
      info4: [],
      foundTokenIndex: -1,
      foundTokenInsta: -1,
      foundTokenTikTok: -1,
      foundTokenYoutube: -1,
      cursorX: 0,
      cursorY: 0,
      isAddingInsta: false,
      isAddingTikTok: false,
      isAddingYoutube: false,
      isInstaConfirmed: false,
      isTikTokConfirmed: false,
      isYoutubeConfirmed: false,
      isInstaAnalyse: false,
      isTikTokAnalyse: false,
      isYoutubeAnalyse: false,
      isInstaEdit: false,
      isTikTokEdit: false,
      isYoutubeEdit: false,
      isInstaChange: false,
      isTikTokChange: false,
      isYoutubeChange: false,
      instaUser: "",
      tikTokUser: "",
      YoutubeUser: "",
      instaLinkHD: "",
      tikTokLinkHD: "",
      YoutubeLinkHD: "",
    };
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    currentComponent() {
      return this.mode === 0 ? 'SignCard' : 'LoginCard';
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.updateCursorPosition);
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateCursorPosition);
  },
  created() {
    this.token = localStorage.getItem('authToken');
    this.verifyUser();
    this.searchUser();
  },
  methods: {
    handleInstaEvent() {
      this.isAddingInsta = true;
    },

    handleYoutubeEvent() {
      this.isAddingYoutube = true;
    },

    handleTikTokEvent() {
      this.isAddingTikTok = true;
    },

    canceling(value){
      this.isInstaEdit = value;
      this.isInstaChange = value;
    },
    canceling2(value){
      this.isTikTokEdit = value;
      this.isTikTokChange = value;
    },
    updateCursorPosition(event) {
      this.cursorX = event.clientX;
      this.cursorY = event.clientY;
    },
    disconnect() {
      try {
        localStorage.clear();  // Clear all local storage items
        this.$router.push({ name: 'Home' });  // Redirect to home page
      } catch (e) {
        console.error('Error during logout:', e);
      }
    },
    changeModeToLogin() {
      this.mode = 1;
    },
    changeModeToSignIn() {
      this.mode = 0;
    },
    async verifyUser() {
      try {
        const response = await fetch('https://swingi.pro/api.php?action=verifyUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: this.userId,
            token: this.token,
          }),
        });
        const data = await response.json();
        if (data.message === 'Authentification réussie') {
          this.isAuthenticated = true;
        } else {
          this.isAuthenticated = false;
        }
      } catch (error) {
        console.error('Error verifying user:', error);
        this.$router.push({ name: 'Home' });
      }
    },
    async searchUser() {
      
      try {
        const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        
        this.info = await response.json();
        
        this.foundTokenIndex = this.info.data.findIndex(item => item.token === this.userId);
        


        if (this.foundTokenIndex !== -1) {

          if (!localStorage.getItem('userName')) {
            if(this.info.data[this.foundTokenIndex].userName !== '' || this.info.data[this.foundTokenIndex].userName !== null) {
              localStorage.setItem('userName', this.info.data[this.foundTokenIndex].userName);
            }
          }
          if (!localStorage.getItem('proImage')) {
            if(this.info.data[this.foundTokenIndex].profileImage !== '' || this.info.data[this.foundTokenIndex].profileImage !== null) {
              localStorage.setItem('proImage', this.info.data[this.foundTokenIndex].profileImage);
            } else {
              localStorage.setItem('proImage', '/g.png');
            }
          }

          try {
            const response2 = await fetch('https://swingi.pro/api.php?action=media&tableName=insta');
            if (!response2.ok) {
              throw new Error(`HTTP error! Status: ${response2.status}`);
            }
            
            this.info2 = await response2.json();

            
            
            this.foundTokenInsta = this.info2.data.findIndex(item => item.token === this.userId);
              

            if (this.foundTokenInsta !== -1) {
              const foundInstaToken = this.info2.data[this.foundTokenInsta];
              localStorage.setItem('instagramUser', foundInstaToken.token);
              localStorage.setItem('instaUser', foundInstaToken.instaUser);
              localStorage.setItem('instaLinkHD', foundInstaToken.instaImage);
              localStorage.setItem('country', foundInstaToken.country);
              localStorage.setItem('qst', foundInstaToken.qst);
              localStorage.setItem('answer', foundInstaToken.answer);
              localStorage.setItem('statisticsID', foundInstaToken.statisticsID);
              localStorage.setItem('dayTime', foundInstaToken.dayTime);

              this.instaUser = foundInstaToken.instaUser;
              this.instaLinkHD = foundInstaToken.instaImage;
              this.isInstaConfirmed = true;
            }
          } catch (e) {
            console.error(e);
            this.isInstaConfirmed = false;
          }

          try {
            const response2 = await fetch('https://swingi.pro/api.php?action=media&tableName=tiktok');
            if (!response2.ok) {
              throw new Error(`HTTP error! Status: ${response2.status}`);
            }
            
            this.info3 = await response2.json();
          
            
            this.foundTokenTikTok = this.info3.data.findIndex(item => item.token === this.userId);
            
              

            if (this.foundTokenTikTok !== -1) {
              
              const foundTikTokToken = this.info3.data[this.foundTokenTikTok];
              localStorage.setItem('tikTokUser', foundTikTokToken.token);
              localStorage.setItem('tikTokUser', foundTikTokToken.tikTokUser);
              localStorage.setItem('tikTokLinkHD', foundTikTokToken.tikTokImage);
              localStorage.setItem('country', foundTikTokToken.country);
              localStorage.setItem('qst', foundTikTokToken.qst);
              localStorage.setItem('answer', foundTikTokToken.answer);
              localStorage.setItem('statisticsID', foundTikTokToken.statisticsID);
              localStorage.setItem('dayTime', foundTikTokToken.dayTime);

              this.tikTokUser = foundTikTokToken.tikTokUser;
              this.tikTokLinkHD = foundTikTokToken.tikTokImage;
              this.istikTokConfirmed = true;
            }
          } catch (e) {
            console.error(e);
            this.isTikTokConfirmed = false;
            
          }


          try {
            const response3 = await fetch('https://swingi.pro/api.php?action=media&tableName=youtube');
            if (!response3.ok) {
              throw new Error(`HTTP error! Status: ${response3.status}`);
            }
            
            this.info4 = await response3.json();

          
            
            this.foundTokenYoutube = this.info4.data.findIndex(item => item.token === this.userId);
            
              

            if (this.foundTokenYoutube !== -1) {
              const foundYoutubeToken = this.info4.data[this.foundTokenYoutube];
              console.log('info4 user : ', foundYoutubeToken.youtubeUser);
              localStorage.setItem('YoutubeUser', foundYoutubeToken.token);
              localStorage.setItem('YoutubeUser', foundYoutubeToken.youtubeUser);
              localStorage.setItem('YoutubeLinkHD', foundYoutubeToken.youtubeImage);
              localStorage.setItem('country', foundYoutubeToken.country);
              localStorage.setItem('qst', foundYoutubeToken.qst);
              localStorage.setItem('answer', foundYoutubeToken.answer);
              localStorage.setItem('statisticsID', foundYoutubeToken.statisticsID);
              localStorage.setItem('dayTime', foundYoutubeToken.dayTime);

              this.YoutubeUser = foundYoutubeToken.youtubeUser;
              this.YoutubeLinkHD = foundYoutubeToken.youtubeImage;
              this.isYoutubeConfirmed = true;
            }
          } catch (e) {
            console.error(e);
            this.isYoutubeConfirmed = false;
            
          }

          
          
          
        }
        

        

        
      } catch (error) {
        this.info = { error: `Error: ${error.message}` };
      }
    },
  },
};
</script>

<style>

.wrapper {
  width: 100%;
  min-width: 200px;
  padding: 15px 20px;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, .6);
  backdrop-filter: blur(2px);
  box-shadow: 0 0 10px #7fadc760;
  border-radius: 10px;
}

.dark .wrapper {
  width: 100%;
  min-width: 200px;
  padding: 15px 20px;
  background: #1c082d40;
  border: 1px solid rgba(20, 20, 20, 0.4);
  backdrop-filter: blur(2px);
  box-shadow: 0 0 10px #00000080;
  border-radius: 10px;
}

.white-space {
  height: 50px;
}

.container {
  width: 100%;
  height: 50px;
  display: flex;
  
  justify-content: center;
  padding-top: 5px;
}

.parent {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}


.loading-bar-follow {
  position: fixed; /* Positionne la barre par rapport à la fenêtre de visualisation */
  top: 0; /* Fixe la barre en haut de la page */
  left: 0; /* Aligne la barre à gauche */
  width: 100%; /* Barre pleine largeur */
  height: 4px;
  background-color: #ff5555; /* Couleur de la barre de chargement */
  animation: loadingFollowAnimation 2s infinite;
  border-radius: 2px;
  pointer-events: none; /* Empêche la barre de capturer les événements de la souris */
  z-index: 9999; /* Place la barre au-dessus de tous les autres éléments */
}

@keyframes loadingFollowAnimation {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}



.textContainer2 {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.button{
  margin: 5px;
  background-color: #00A3FF80;
  width: 90%;
  height: 30px;
  backdrop-filter: blur(2px);
  padding: 5px;
  border-radius: 2px;
  display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}


.button:hover {
  background-color: #00A3FF; /* Couleur de fond lors du survol */
  cursor: pointer;
}

.cancel{
  margin: 5px;
  background-color: #ff555580;
  width: 20%;
  height: 30px;
  backdrop-filter: blur(2px);
  padding: 5px;
  border-radius: 2px;
  color: black;
  display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}


.cancel:hover {
  background-color: #ff5555; /* Couleur de fond lors du survol */
  cursor: pointer;
}

.label2 {
  font-size: 12px;
}



.transition-wrapper {
  margin-top: 40px;
  height: 100%; /* Fixe la hauteur en fonction de vos besoins */
  width: 100%;   /* Fixe la largeur en fonction de vos besoins */
  min-width: 200px;
  overflow: hidden;
  display: flex;
    justify-content: center;
    align-items: center;
}

.fade-leave-active{
  transition: opacity 0.35s, transform 0.4s;
}


.fade-leave-to {
  opacity: 0;
  transform: translateX(20%);
}

.fade-leave {
  opacity: 1;
  transform: translateX(0%);
}
</style>


