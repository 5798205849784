<template>
    <div class="body3">
      <div :style="{height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
        <div :style="{display: 'flex', alignItems: 'center', marginLeft: '5px'}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#FE2C55" fill="none">
                <path d="M12 20.5C13.8097 20.5 15.5451 20.3212 17.1534 19.9934C19.1623 19.5839 20.1668 19.3791 21.0834 18.2006C22 17.0221 22 15.6693 22 12.9635V11.0365C22 8.33073 22 6.97787 21.0834 5.79937C20.1668 4.62088 19.1623 4.41613 17.1534 4.00662C15.5451 3.67877 13.8097 3.5 12 3.5C10.1903 3.5 8.45489 3.67877 6.84656 4.00662C4.83766 4.41613 3.83321 4.62088 2.9166 5.79937C2 6.97787 2 8.33073 2 11.0365V12.9635C2 15.6693 2 17.0221 2.9166 18.2006C3.83321 19.3791 4.83766 19.5839 6.84656 19.9934C8.45489 20.3212 10.1903 20.5 12 20.5Z" stroke="currentColor" stroke-width="1.5" />
                <path d="M15.9621 12.3129C15.8137 12.9187 15.0241 13.3538 13.4449 14.2241C11.7272 15.1705 10.8684 15.6438 10.1728 15.4615C9.9372 15.3997 9.7202 15.2911 9.53799 15.1438C9 14.7089 9 13.8059 9 12C9 10.1941 9 9.29112 9.53799 8.85618C9.7202 8.70886 9.9372 8.60029 10.1728 8.53854C10.8684 8.35621 11.7272 8.82945 13.4449 9.77593C15.0241 10.6462 15.8137 11.0813 15.9621 11.6871C16.0126 11.8933 16.0126 12.1067 15.9621 12.3129Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            </svg>
          <p class="YoutubeTitle">
            Youtube
          </p>
        </div>

        <div :style="{display: 'flex', fontSize: '1.5vh', fontFamily: 'Josefin Sans, sans-serif', color: '#7fadc7', fontWeight: 'bold'}">
          <div :style="{
            width: '30px', 
          display: 'flex', 
          justifyContent: 'center', 
          flexDirection: 'column', 
          marginInline: '5px', 
          paddingInline: '10px', 
          cursor: 'pointer', 
          textAlign: 'center', 
          alignItems: 'center', 
          
          borderRadius: '2px'}"
          class="forBg"
          :class="{ hoverEffect: isHovered0 }"
          @mouseover="isHovered0 = true"
          @mouseleave="isHovered0 = false" @click="cardYoutube">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M14 3.5C17.7712 3.5 19.6569 3.5 20.8284 4.7448C22 5.98959 22 7.99306 22 12C22 16.0069 22 18.0104 20.8284 19.2552C19.6569 20.5 17.7712 20.5 14 20.5L10 20.5C6.22876 20.5 4.34315 20.5 3.17157 19.2552C2 18.0104 2 16.0069 2 12C2 7.99306 2 5.98959 3.17157 4.7448C4.34315 3.5 6.22876 3.5 10 3.5L14 3.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M5 15.5C6.60865 13.3626 10.3539 13.246 12 15.5M10.249 10.2501C10.249 11.2166 9.46552 12.0001 8.49902 12.0001C7.53253 12.0001 6.74902 11.2166 6.74902 10.2501C6.74902 9.28362 7.53253 8.50012 8.49902 8.50012C9.46552 8.50012 10.249 9.28362 10.249 10.2501Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 9.5L19 9.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 13.5H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          
          </div>
          
          <div :style="{
            width: '30px', 
            display: 'flex', 
            justifyContent: 'center', 
            flexDirection: 'column', 
            marginInline: '5px', 
            paddingInline: '10px', 
            cursor: 'pointer', 
            textAlign: 'center', 
            alignItems: 'center', 
            
            borderRadius: '5px'}"
            class="forBg"
            :class="{ hoverEffect: isHovered1 }"
            @mouseover="isHovered1 = true"
            @mouseleave="isHovered1 = false" @click="statYoutube">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M21 21H10C6.70017 21 5.05025 21 4.02513 19.9749C3 18.9497 3 17.2998 3 14V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 4H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 7H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M5 20C6.07093 18.053 7.52279 13.0189 10.3063 13.0189C12.2301 13.0189 12.7283 15.4717 14.6136 15.4717C17.8572 15.4717 17.387 10 21 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
          <div :style="{
            width: '30px', 
            display: 'flex', 
            justifyContent: 'center', 
            flexDirection: 'column',
             marginInline: '5px', 
             paddingInline: '10px', 
             cursor: 'pointer', 
             textAlign: 'center', 
             alignItems: 'center', 
             
             borderRadius: '5px'}"
             class="forBg"
             :class="{ hoverEffect: isHovered2 }"
             @mouseover="isHovered2 = true"
             @mouseleave="isHovered2 = false" @click="changeYoutube">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
            <div :style="{
              width: '30px', 
              display: 'flex', 
              justifyContent: 'center', 
              flexDirection: 'column', 
              marginInline: '5px', 
              paddingInline: '10px', 
              cursor: 'pointer', 
              textAlign: 'center', 
              alignItems: 'center', 
              backgroundColor: '#ff5555', 
              borderRadius: '5px'}"
              >
              <svg :style="{color: 'white'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
                <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            </svg>
            
            </div>
            <div :style="{width: '30px', marginInline: '10px', paddingInline: '10px'}">
          
            </div>

            
            
            
            
        </div>
        

        
      </div>

      <div :style="{width: '100%', display: 'flex', justifyContent: 'center'}">
        <div class="instaViewBox" :class="{ hoverEffect3: isHoveredPro }"
        @mouseover="isHoveredPro = true"
        @mouseleave="isHoveredPro = false">
          <div class="YoutubeImage">
            <img :src="YoutubeImage" alt="Profile Picture" class="profileImage">
          </div>
          <div class="instaName">
            <p class="userTitle">{{ YoutubeUser }}</p>
          </div>
        </div>
      </div>
      
  
      <div v-if="isEditing">
        <form>
          <ul>
            <li>
              <div class="structure-box">
                <div class="dscr">
                  <text>Your Youtube Account Country :</text>
                </div>           
               
                <div class="dropdown">
                  <div class="dropbtn" @click="toggleDropdown">{{ dDContent }}</div>
                  <div class="dropdown-content" v-if="isDropdownOpen">
                    <a v-for="country in countries" :key="country.name" @click.prevent="updateContent(country.name)">
                      <img :src="country.flag" alt="Flag of {{ country.name }}" class="flag-icon" />
                      {{ country.name }}
                    </a>
                  </div>
                </div>
              </div>
              
  
              <div class="structure-box">
                <div class="dscr">
                  <text>Searching Description :</text>
                </div>
                <div class="short-text">
                  <text> - Whrite Short Description To Explane What To Search For Getting Trafics On Your Account</text>
                </div>
                <div class="short-text">
                  <text>ex: search on Youtube for how to get money for free with Swingi</text>
                </div>  
               
                <textarea
                  type="text"
                  ref="autoResizeInput"
                  v-model="sDescription"
                  @input="autoResizeInput"
                />
              </div>
  
              <div class="structure-box">
                <div class="dscr">
                  <text>Simple Quastion :</text>
                </div>
                <div class="short-text">
                  <text>- Whrite Simple Question Basing On Your Searching Description</text>
                </div>  
                <div class="short-text">
                  <text>ex: in my video how many times I say Swingi</text>
                </div>  
               
                <textarea
                  type="text"
                  ref="autoResizeInput"
                  v-model="sQst"
                  @input="autoResizeInput"
                />
              </div>
  
              
  
              
  
              <div class="structure-box">
                <div class="dscr">
                  <text>Correct Answer :</text>
                </div>
                <div class="short-text">
                  <text>- Whrite Your Correct Answer Here</text>
                </div>  
                <div class="short-text">
                  <text>ex: 3 Times</text>
                </div>  
               
                <textarea
                  type="correct-answer"
                  ref="autoResizeInput"
                  v-model="cAnswer"
                  @input="autoResizeInput"
                />
              </div>
  
              
  
              <div class="structure-box">
                <div class="dscr">
                  <text>Wrong Answer 1 :</text>
                </div>
                <div class="short-text">
                  <text>- Whrite Your Wrong Answer 1 Here</text>
                </div> 
                <div class="short-text">
                  <text>ex: 2 Times</text>
                </div>  
               
                <textarea
                  type="wrong-answer"
                  ref="autoResizeInput"
                  v-model="wAnswer1"
                  @input="autoResizeInput"
                />
              </div>
  
             
  
              <div class="structure-box">
                <div class="dscr">
                  <text>Wrong Answer 2 :</text>
                </div>
                <div class="short-text">
                  <text>- Whrite Your Wrong Answer 2 Here</text>
                </div> 
                <div class="short-text">
                  <text>ex: Once</text>
                </div>  
               
                <textarea
                  type="wrong-answer"
                  ref="autoResizeInput"
                  v-model="wAnswer2"
                  @input="autoResizeInput"
                />
              </div>
  
              <div class="structure-box2">
                <!-- Utilisation de v-model pour lier la valeur de la checkbox -->
                <CheckBox v-model="isChecked" label="Activate After Saving" @input="isAccepted" :value="isChecked"/>
  
              </div>

              <div :style="{display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                <svg :style="{
                  width: '20px',
                  height: '20px',
                  minHeight: '20px',
                  minWidth: '20px'
                }"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#ff5555" fill="none">
                  <path d="M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z" stroke="currentColor" stroke-width="1.5" />
                  <path d="M11.992 16H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 13L12 8.99997" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <div :style="{
                
                
                margin: '2px 15px 0 15px',
                padding: '5px',
                fontSize: '2vh',
                border: '2px dashed #ff5555',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Josefin Sans, sans-serif',
                fontWeight: 'bold'
              }">              
              Activating this will cost you SP for each person answering your QST.
                </div>
              </div>
  
  
  
              
  
            </li>
          </ul>
  
          <div v-if="!isSaving" :style="{
            marginTop: '20px',
            maxWidth: '100%',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            marginInline: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            border: '2px dashed #7fadc7',
            color: '#7fadc7'
          }" 
          :class="{hoverEffect2: isHoveredYoutube}"
          @mouseover="isHoveredYoutube = true"
          @mouseleave="isHoveredYoutube = false" 
          @click="saveYoutubeQst">
            Save
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M12.5 18C12.5 18 18.5 13.5811 18.5 12C18.5 10.4188 12.5 6 12.5 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.50005 18C5.50005 18 11.5 13.5811 11.5 12C11.5 10.4188 5.5 6 5.5 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        <div v-else :style="{
          maxWidth: '100%',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          marginInline: '5px',
          paddingInline: '10px',
          textAlign: 'center',
          alignItems: 'center',
          
          borderRadius: '5px',
          color: '#7fadc7'
        }" >
        <Loading type="submit" :width="'40px'"/>
        </div>

  
          <div class="structure-box2">
            <div v-if="errorInputing" class="errorText">
              {{ textErrorInputing }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  
  import CheckBox from "../components/CheckBox.vue";
  import Loading from '../components/items/loading.vue';
  
  export default {
    name: "InstagramEdit",
    components: {
      CheckBox,
      Loading,
      
    },
  
    data() {
      return {
        YoutubeUser: localStorage.getItem('YoutubeUser'),
        YoutubeImage: localStorage.getItem('YoutubeLinkHD'),
        isYoutubeConfirmed: false,
        sQst: '', // Add this line to store input value
        sDescription: '', // Add this line to store input value
        cAnswer: '', // Add this line to store input value
        wAnswer1: '', // Add this line to store input value
        wAnswer2: '', // Add this line to store input value
        isEditing: true,
        dDContent: 'World',
        isDropdownOpen: false,
        errorInputing: false,
        textErrorInputing: 'Pleas make sur ther was no empty Value',
        isChecked: this.value,
        isHovered0: false,
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
      isHoveredYoutube: false,
      isHoveredPro: false,
      isSaving: false,
        
        countries: [
          { name: 'World', flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Flag_of_FOTW.svg/1200px-Flag_of_FOTW.svg.png' },
    { name: 'Afghanistan', flag: 'https://flagpedia.net/data/flags/h80/af.png' },
    { name: 'Albania', flag: 'https://flagpedia.net/data/flags/h80/al.png' },
    { name: 'Algeria', flag: 'https://flagpedia.net/data/flags/h80/dz.png' },
    { name: 'Andorra', flag: 'https://flagpedia.net/data/flags/h80/ad.png' },
    { name: 'Angola', flag: 'https://flagpedia.net/data/flags/h80/ao.png' },
    { name: 'Antigua and Barbuda', flag: 'https://flagpedia.net/data/flags/h80/ag.png' },
    { name: 'Argentina', flag: 'https://flagpedia.net/data/flags/h80/ar.png' },
    { name: 'Armenia', flag: 'https://flagpedia.net/data/flags/h80/am.png' },
    { name: 'Australia', flag: 'https://flagpedia.net/data/flags/h80/au.png' },
    { name: 'Austria', flag: 'https://flagpedia.net/data/flags/h80/at.png' },
    { name: 'Azerbaijan', flag: 'https://flagpedia.net/data/flags/h80/az.png' },
    { name: 'Bahamas', flag: 'https://flagpedia.net/data/flags/h80/bs.png' },
    { name: 'Bahrain', flag: 'https://flagpedia.net/data/flags/h80/bh.png' },
    { name: 'Bangladesh', flag: 'https://flagpedia.net/data/flags/h80/bd.png' },
    { name: 'Barbados', flag: 'https://flagpedia.net/data/flags/h80/bb.png' },
    { name: 'Belarus', flag: 'https://flagpedia.net/data/flags/h80/by.png' },
    { name: 'Belgium', flag: 'https://flagpedia.net/data/flags/h80/be.png' },
    { name: 'Belize', flag: 'https://flagpedia.net/data/flags/h80/bz.png' },
    { name: 'Benin', flag: 'https://flagpedia.net/data/flags/h80/bj.png' },
    { name: 'Bhutan', flag: 'https://flagpedia.net/data/flags/h80/bt.png' },
    { name: 'Bolivia', flag: 'https://flagpedia.net/data/flags/h80/bo.png' },
    { name: 'Bosnia and Herzegovina', flag: 'https://flagpedia.net/data/flags/h80/ba.png' },
    { name: 'Botswana', flag: 'https://flagpedia.net/data/flags/h80/bw.png' },
    { name: 'Brazil', flag: 'https://flagpedia.net/data/flags/h80/br.png' },
    { name: 'Brunei', flag: 'https://flagpedia.net/data/flags/h80/bn.png' },
    { name: 'Bulgaria', flag: 'https://flagpedia.net/data/flags/h80/bg.png' },
    { name: 'Burkina Faso', flag: 'https://flagpedia.net/data/flags/h80/bf.png' },
    { name: 'Burundi', flag: 'https://flagpedia.net/data/flags/h80/bi.png' },
    { name: 'Cabo Verde', flag: 'https://flagpedia.net/data/flags/h80/cv.png' },
    { name: 'Cambodia', flag: 'https://flagpedia.net/data/flags/h80/kh.png' },
    { name: 'Cameroon', flag: 'https://flagpedia.net/data/flags/h80/cm.png' },
    { name: 'Canada', flag: 'https://flagpedia.net/data/flags/h80/ca.png' },
    { name: 'Central African Republic', flag: 'https://flagpedia.net/data/flags/h80/cf.png' },
    { name: 'Chad', flag: 'https://flagpedia.net/data/flags/h80/td.png' },
    { name: 'Chile', flag: 'https://flagpedia.net/data/flags/h80/cl.png' },
    { name: 'China', flag: 'https://flagpedia.net/data/flags/h80/cn.png' },
    { name: 'Colombia', flag: 'https://flagpedia.net/data/flags/h80/co.png' },
    { name: 'Comoros', flag: 'https://flagpedia.net/data/flags/h80/km.png' },
    { name: 'Congo (Congo-Brazzaville)', flag: 'https://flagpedia.net/data/flags/h80/cg.png' },
    { name: 'Costa Rica', flag: 'https://flagpedia.net/data/flags/h80/cr.png' },
    { name: 'Croatia', flag: 'https://flagpedia.net/data/flags/h80/hr.png' },
    { name: 'Cuba', flag: 'https://flagpedia.net/data/flags/h80/cu.png' },
    { name: 'Cyprus', flag: 'https://flagpedia.net/data/flags/h80/cy.png' },
    { name: 'Czechia', flag: 'https://flagpedia.net/data/flags/h80/cz.png' },
    { name: 'Democratic Republic of the Congo', flag: 'https://flagpedia.net/data/flags/h80/cd.png' },
    { name: 'Denmark', flag: 'https://flagpedia.net/data/flags/h80/dk.png' },
    { name: 'Djibouti', flag: 'https://flagpedia.net/data/flags/h80/dj.png' },
    { name: 'Dominica', flag: 'https://flagpedia.net/data/flags/h80/dm.png' },
    { name: 'Dominican Republic', flag: 'https://flagpedia.net/data/flags/h80/do.png' },
    { name: 'Ecuador', flag: 'https://flagpedia.net/data/flags/h80/ec.png' },
    { name: 'Egypt', flag: 'https://flagpedia.net/data/flags/h80/eg.png' },
    { name: 'El Salvador', flag: 'https://flagpedia.net/data/flags/h80/sv.png' },
    { name: 'Equatorial Guinea', flag: 'https://flagpedia.net/data/flags/h80/gq.png' },
    { name: 'Eritrea', flag: 'https://flagpedia.net/data/flags/h80/er.png' },
    { name: 'Estonia', flag: 'https://flagpedia.net/data/flags/h80/ee.png' },
    { name: 'Eswatini', flag: 'https://flagpedia.net/data/flags/h80/sz.png' },
    { name: 'Ethiopia', flag: 'https://flagpedia.net/data/flags/h80/et.png' },
    { name: 'Fiji', flag: 'https://flagpedia.net/data/flags/h80/fj.png' },
    { name: 'Finland', flag: 'https://flagpedia.net/data/flags/h80/fi.png' },
    { name: 'France', flag: 'https://flagpedia.net/data/flags/h80/fr.png' },
    { name: 'Gabon', flag: 'https://flagpedia.net/data/flags/h80/ga.png' },
    { name: 'Gambia', flag: 'https://flagpedia.net/data/flags/h80/gm.png' },
    { name: 'Georgia', flag: 'https://flagpedia.net/data/flags/h80/ge.png' },
    { name: 'Germany', flag: 'https://flagpedia.net/data/flags/h80/de.png' },
    { name: 'Ghana', flag: 'https://flagpedia.net/data/flags/h80/gh.png' },
    { name: 'Greece', flag: 'https://flagpedia.net/data/flags/h80/gr.png' },
    { name: 'Grenada', flag: 'https://flagpedia.net/data/flags/h80/gd.png' },
    { name: 'Guatemala', flag: 'https://flagpedia.net/data/flags/h80/gt.png' },
    { name: 'Guinea', flag: 'https://flagpedia.net/data/flags/h80/gn.png' },
    { name: 'Guinea-Bissau', flag: 'https://flagpedia.net/data/flags/h80/gw.png' },
    { name: 'Guyana', flag: 'https://flagpedia.net/data/flags/h80/gy.png' },
    { name: 'Haiti', flag: 'https://flagpedia.net/data/flags/h80/ht.png' },
    { name: 'Honduras', flag: 'https://flagpedia.net/data/flags/h80/hn.png' },
    { name: 'Hungary', flag: 'https://flagpedia.net/data/flags/h80/hu.png' },
    { name: 'Iceland', flag: 'https://flagpedia.net/data/flags/h80/is.png' },
    { name: 'India', flag: 'https://flagpedia.net/data/flags/h80/in.png' },
    { name: 'Indonesia', flag: 'https://flagpedia.net/data/flags/h80/id.png' },
    { name: 'Iran', flag: 'https://flagpedia.net/data/flags/h80/ir.png' },
    { name: 'Iraq', flag: 'https://flagpedia.net/data/flags/h80/iq.png' },
    { name: 'Ireland', flag: 'https://flagpedia.net/data/flags/h80/ie.png' },
    { name: 'Israel', flag: 'https://flagpedia.net/data/flags/h80/il.png' },
    { name: 'Italy', flag: 'https://flagpedia.net/data/flags/h80/it.png' },
    { name: 'Jamaica', flag: 'https://flagpedia.net/data/flags/h80/jm.png' },
    { name: 'Japan', flag: 'https://flagpedia.net/data/flags/h80/jp.png' },
    { name: 'Jordan', flag: 'https://flagpedia.net/data/flags/h80/jo.png' },
    { name: 'Kazakhstan', flag: 'https://flagpedia.net/data/flags/h80/kz.png' },
    { name: 'Kenya', flag: 'https://flagpedia.net/data/flags/h80/ke.png' },
    { name: 'Kiribati', flag: 'https://flagpedia.net/data/flags/h80/ki.png' },
    { name: 'Kuwait', flag: 'https://flagpedia.net/data/flags/h80/kw.png' },
    { name: 'Kyrgyzstan', flag: 'https://flagpedia.net/data/flags/h80/kg.png' },
    { name: 'Laos', flag: 'https://flagpedia.net/data/flags/h80/la.png' },
    { name: 'Latvia', flag: 'https://flagpedia.net/data/flags/h80/lv.png' },
    { name: 'Lebanon', flag: 'https://flagpedia.net/data/flags/h80/lb.png' },
    { name: 'Lesotho', flag: 'https://flagpedia.net/data/flags/h80/ls.png' },
    { name: 'Liberia', flag: 'https://flagpedia.net/data/flags/h80/lr.png' },
    { name: 'Libya', flag: 'https://flagpedia.net/data/flags/h80/ly.png' },
    { name: 'Liechtenstein', flag: 'https://flagpedia.net/data/flags/h80/li.png' },
    { name: 'Lithuania', flag: 'https://flagpedia.net/data/flags/h80/lt.png' },
    { name: 'Luxembourg', flag: 'https://flagpedia.net/data/flags/h80/lu.png' },
    { name: 'Madagascar', flag: 'https://flagpedia.net/data/flags/h80/mg.png' },
    { name: 'Malawi', flag: 'https://flagpedia.net/data/flags/h80/mw.png' },
    { name: 'Malaysia', flag: 'https://flagpedia.net/data/flags/h80/my.png' },
    { name: 'Maldives', flag: 'https://flagpedia.net/data/flags/h80/mv.png' },
    { name: 'Mali', flag: 'https://flagpedia.net/data/flags/h80/ml.png' },
    { name: 'Malta', flag: 'https://flagpedia.net/data/flags/h80/mt.png' },
    { name: 'Marshall Islands', flag: 'https://flagpedia.net/data/flags/h80/mh.png' },
    { name: 'Mauritania', flag: 'https://flagpedia.net/data/flags/h80/mr.png' },
    { name: 'Mauritius', flag: 'https://flagpedia.net/data/flags/h80/mu.png' },
    { name: 'Mexico', flag: 'https://flagpedia.net/data/flags/h80/mx.png' },
    { name: 'Micronesia', flag: 'https://flagpedia.net/data/flags/h80/fm.png' },
    { name: 'Moldova', flag: 'https://flagpedia.net/data/flags/h80/md.png' },
    { name: 'Monaco', flag: 'https://flagpedia.net/data/flags/h80/mc.png' },
    { name: 'Mongolia', flag: 'https://flagpedia.net/data/flags/h80/mn.png' },
    { name: 'Montenegro', flag: 'https://flagpedia.net/data/flags/h80/me.png' },
    { name: 'Morocco', flag: 'https://flagpedia.net/data/flags/h80/ma.png' },
    { name: 'Mozambique', flag: 'https://flagpedia.net/data/flags/h80/mz.png' },
    { name: 'Myanmar', flag: 'https://flagpedia.net/data/flags/h80/mm.png' },
    { name: 'Namibia', flag: 'https://flagpedia.net/data/flags/h80/na.png' },
    { name: 'Nauru', flag: 'https://flagpedia.net/data/flags/h80/nr.png' },
    { name: 'Nepal', flag: 'https://flagpedia.net/data/flags/h80/np.png' },
    { name: 'Netherlands', flag: 'https://flagpedia.net/data/flags/h80/nl.png' },
    { name: 'New Zealand', flag: 'https://flagpedia.net/data/flags/h80/nz.png' },
    { name: 'Nicaragua', flag: 'https://flagpedia.net/data/flags/h80/ni.png' },
    { name: 'Niger', flag: 'https://flagpedia.net/data/flags/h80/ne.png' },
    { name: 'Nigeria', flag: 'https://flagpedia.net/data/flags/h80/ng.png' },
    { name: 'North Korea', flag: 'https://flagpedia.net/data/flags/h80/kp.png' },
    { name: 'North Macedonia', flag: 'https://flagpedia.net/data/flags/h80/mk.png' },
    { name: 'Norway', flag: 'https://flagpedia.net/data/flags/h80/no.png' },
    { name: 'Oman', flag: 'https://flagpedia.net/data/flags/h80/om.png' },
    { name: 'Pakistan', flag: 'https://flagpedia.net/data/flags/h80/pk.png' },
    { name: 'Palau', flag: 'https://flagpedia.net/data/flags/h80/pw.png' },
    { name: 'Palestine', flag: 'https://flagpedia.net/data/flags/h80/ps.png' },
    { name: 'Panama', flag: 'https://flagpedia.net/data/flags/h80/pa.png' },
    { name: 'Papua New Guinea', flag: 'https://flagpedia.net/data/flags/h80/pg.png' },
    { name: 'Paraguay', flag: 'https://flagpedia.net/data/flags/h80/py.png' },
    { name: 'Peru', flag: 'https://flagpedia.net/data/flags/h80/pe.png' },
    { name: 'Philippines', flag: 'https://flagpedia.net/data/flags/h80/ph.png' },
    { name: 'Poland', flag: 'https://flagpedia.net/data/flags/h80/pl.png' },
    { name: 'Portugal', flag: 'https://flagpedia.net/data/flags/h80/pt.png' },
    { name: 'Qatar', flag: 'https://flagpedia.net/data/flags/h80/qa.png' },
    { name: 'Romania', flag: 'https://flagpedia.net/data/flags/h80/ro.png' },
    { name: 'Russia', flag: 'https://flagpedia.net/data/flags/h80/ru.png' },
    { name: 'Rwanda', flag: 'https://flagpedia.net/data/flags/h80/rw.png' },
    { name: 'Saint Kitts and Nevis', flag: 'https://flagpedia.net/data/flags/h80/kn.png' },
    { name: 'Saint Lucia', flag: 'https://flagpedia.net/data/flags/h80/lc.png' },
    { name: 'Saint Vincent and the Grenadines', flag: 'https://flagpedia.net/data/flags/h80/vc.png' },
    { name: 'Samoa', flag: 'https://flagpedia.net/data/flags/h80/ws.png' },
    { name: 'San Marino', flag: 'https://flagpedia.net/data/flags/h80/sm.png' },
    { name: 'Sao Tome and Principe', flag: 'https://flagpedia.net/data/flags/h80/st.png' },
    { name: 'Saudi Arabia', flag: 'https://flagpedia.net/data/flags/h80/sa.png' },
    { name: 'Senegal', flag: 'https://flagpedia.net/data/flags/h80/sn.png' },
    { name: 'Serbia', flag: 'https://flagpedia.net/data/flags/h80/rs.png' },
        { name: 'Seychelles', flag: 'https://flagpedia.net/data/flags/h80/sc.png' },
    { name: 'Sierra Leone', flag: 'https://flagpedia.net/data/flags/h80/sl.png' },
    { name: 'Singapore', flag: 'https://flagpedia.net/data/flags/h80/sg.png' },
    { name: 'Slovakia', flag: 'https://flagpedia.net/data/flags/h80/sk.png' },
    { name: 'Slovenia', flag: 'https://flagpedia.net/data/flags/h80/si.png' },
    { name: 'Solomon Islands', flag: 'https://flagpedia.net/data/flags/h80/sb.png' },
    { name: 'Somalia', flag: 'https://flagpedia.net/data/flags/h80/so.png' },
    { name: 'South Africa', flag: 'https://flagpedia.net/data/flags/h80/za.png' },
    { name: 'South Korea', flag: 'https://flagpedia.net/data/flags/h80/kr.png' },
    { name: 'South Sudan', flag: 'https://flagpedia.net/data/flags/h80/ss.png' },
    { name: 'Spain', flag: 'https://flagpedia.net/data/flags/h80/es.png' },
    { name: 'Sri Lanka', flag: 'https://flagpedia.net/data/flags/h80/lk.png' },
    { name: 'Sudan', flag: 'https://flagpedia.net/data/flags/h80/sd.png' },
    { name: 'Suriname', flag: 'https://flagpedia.net/data/flags/h80/sr.png' },
    { name: 'Sweden', flag: 'https://flagpedia.net/data/flags/h80/se.png' },
    { name: 'Switzerland', flag: 'https://flagpedia.net/data/flags/h80/ch.png' },
    { name: 'Syria', flag: 'https://flagpedia.net/data/flags/h80/sy.png' },
    { name: 'Taiwan', flag: 'https://flagpedia.net/data/flags/h80/tw.png' },
    { name: 'Tajikistan', flag: 'https://flagpedia.net/data/flags/h80/tj.png' },
    { name: 'Tanzania', flag: 'https://flagpedia.net/data/flags/h80/tz.png' },
    { name: 'Thailand', flag: 'https://flagpedia.net/data/flags/h80/th.png' },
    { name: 'Togo', flag: 'https://flagpedia.net/data/flags/h80/tg.png' },
    { name: 'Tonga', flag: 'https://flagpedia.net/data/flags/h80/to.png' },
    { name: 'Trinidad and Tobago', flag: 'https://flagpedia.net/data/flags/h80/tt.png' },
    { name: 'Tunisia', flag: 'https://flagpedia.net/data/flags/h80/tn.png' },
    { name: 'Turkey', flag: 'https://flagpedia.net/data/flags/h80/tr.png' },
    { name: 'Turkmenistan', flag: 'https://flagpedia.net/data/flags/h80/tm.png' },
    { name: 'Tuvalu', flag: 'https://flagpedia.net/data/flags/h80/tv.png' },
    { name: 'Uganda', flag: 'https://flagpedia.net/data/flags/h80/ug.png' },
    { name: 'Ukraine', flag: 'https://flagpedia.net/data/flags/h80/ua.png' },
    { name: 'United Arab Emirates', flag: 'https://flagpedia.net/data/flags/h80/ae.png' },
    { name: 'United Kingdom', flag: 'https://flagpedia.net/data/flags/h80/gb.png' },
    { name: 'United States', flag: 'https://flagpedia.net/data/flags/h80/us.png' },
    { name: 'Uruguay', flag: 'https://flagpedia.net/data/flags/h80/uy.png' },
    { name: 'Uzbekistan', flag: 'https://flagpedia.net/data/flags/h80/uz.png' },
    { name: 'Vanuatu', flag: 'https://flagpedia.net/data/flags/h80/vu.png' },
    { name: 'Vatican City', flag: 'https://flagpedia.net/data/flags/h80/va.png' },
    { name: 'Venezuela', flag: 'https://flagpedia.net/data/flags/h80/ve.png' },
    { name: 'Vietnam', flag: 'https://flagpedia.net/data/flags/h80/vn.png' },
    { name: 'Yemen', flag: 'https://flagpedia.net/data/flags/h80/ye.png' },
    { name: 'Zambia', flag: 'https://flagpedia.net/data/flags/h80/zm.png' },
    { name: 'Zimbabwe', flag: 'https://flagpedia.net/data/flags/h80/zw.png' }
  ],
      };
    },
  
    async created(){
      await this.getYoutubeQst();
    },
  
  
    methods: {

      cardYoutube(){
      this.$emit('Youtube-card');
    },

    changeYoutube(){
      this.$emit('Youtube-change');
    },

    statYoutube(){
      this.$emit('Youtube-stat');
    },


      proxyImage(url) {
        return `https://swingi.pro/api.php?action=proxyImage&url=${encodeURIComponent(url)}`;
      },
  
      async saveYoutubeQst() {

        this.isSaving = true;
        this.textErrorInputing = '';
    // Check if all required inputs are provided
        if (this.sQst && this.wAnswer2 && this.sDescription && this.cAnswer && this.wAnswer1) {
          try {
            // Call the method to add the Instagram question
            await this.addYoutubeQst();
            
          } catch (error) {
            // Handle errors from addYoutubeQst
            this.errorInputing = true;
            this.isSaving = false;
            this.textErrorInputing = 'Error while saving Youtube question: ', error;
          }
        } else {
          this.errorInputing = true;
          this.isSaving = false;
          this.textErrorInputing = 'Check if all required inputs are provided';
        }
      },
  
  async getYoutubeQst() {
    try {
  
      var token = localStorage.getItem('authToken');
      
      const response = await fetch(`https://swingi.pro/api.php?action=getMediaQst&token=${encodeURIComponent(token)}&tableName=youtubeQst`, {
        method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
  
      if (data.error) {
        console.error('Erreur:', data.error, 'Détails:', data.details);
      } else {
        
        if (data.successe === false){
          this.$emit('Youtube-active', false);
          localStorage.setItem('Youtube-Active', false);
        } else {
          if (data.data) {
            localStorage.setItem('Youtube-Active', true);
          this.youtubeQst = data.data;
            this.sQst = this.youtubeQst[2];
            this.sDescription = this.youtubeQst[1];
            this.cAnswer = this.youtubeQst[3];
            this.wAnswer1 = this.youtubeQst[4];
            this.wAnswer2 = this.youtubeQst[5];
            this.dDContent = this.youtubeQst[6];
            if (this.youtubeQst[7] == '0'){
              this.$emit('Youtube-active', false);
              this.isChecked = false;
            } else if (this.youtubeQst[7] == '1') {
              this.$emit('Youtube-active', true);
              this.isChecked = true;
            }
          } else {
            this.$emit('Youtube-active', false);
          localStorage.setItem('Youtube-Active', false);
          }
          
  
            console.log('this is YoutubeQst:', this.isChecked);
          
        }
      }
  
  
    } catch (error) {
      console.error('Erreur lors de la requête POST:', error);
      this.info = { error: `Error: ${error.message}` };
    }
  },
  
  async cancel() {
    this.isYoutubeEdit = false;
        this.$emit('cancel', false);
  },
  
  isAccepted(value) {
        
        this.isChecked = !value;
  
      },
  
      toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen; // Toggle dropdown visibility
      },
      updateContent(value) {
        this.dDContent = value; // Update the dropdown content
        this.isDropdownOpen = false; // Close the dropdown after selection
      },
  
      editInsta() {
        this.isYoutubeEdit = true;
        this.isYoutubeChange = false;
        this.$emit('Youtube-edit');
      },
  
      changeInsta() {
        this.isYoutubeEdit = false;
        this.isYoutubeChange = true;
        this.$emit('Youtube-change');
      },
  
      autoResizeInput() {
        const input = this.$refs.autoResizeInput;
        input.style.height = 'auto';
        input.style.height = (input.scrollHeight) + 'px';
      },
  
      async addYoutubeQst() {
        var value = 0;
        if (this.isChecked === false) {
          value = 0;
          localStorage.setItem('Youtube-Active', false);
        } else {
          value = 1;
          localStorage.setItem('Youtube-Active', true);
        }
    const userDetails = {
      token: localStorage.getItem('authToken'),
      dscr: this.sDescription,
      qst: this.sQst,
      answer1: this.cAnswer,
      answer2: this.wAnswer1,
      answer3: this.wAnswer2,
      country: this.dDContent,
      activ: value,
      dayTime: new Date().toISOString(),
      tableName: 'youtubeQst'
    };
  
    this.textErrorInputing = 'Saving Data';
  
    try {
      var response = await fetch('https://swingi.pro/api.php?action=saveMediaQst', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userDetails)
      });
  
      
  
      if (!response.ok) {
        this.errorInputing = true;
        this.isSaving = false;
        this.textErrorInputing = 'Network response was not ok';
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
  
  
      if (!data.success) {
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = data.message;
    } else {
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = 'Data Saved Correctly';
    }
  
      this.canGo = true;
    } catch (error) {
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = 'Error in posting request: ', error;
      this.info = { error: `Error: ${error.message}` };
    }
  }
    },
  
    mounted() {
      this.$nextTick(() => {
        const input = this.$refs.autoResizeInput;
        if (input) {
          input.setAttribute('style', 'height:' + (input.scrollHeight) + 'px;overflow-y:hidden;');
        }
      });
    }
  }
  </script>
  
  <style>
  

  
    .YoutubeImage{
      margin-right: 5px;
      height: 40px;
      width: 40px;
  
    }

  
  </style>