<template>
    <header>
      <div v-if="isMenuVisible"   
      :style="{
        width: '100vw', 
        height: '100vh', 
        backgroundColor: 'transparent', 
        position: 'fixed', 
        top: '0', 
        left: '0', 
        zIndex: '0',
        overflow: 'hidden'
      }"  @click="isMenuVisible = false">

      </div>
        <nav v-if="token" class="navbar-contents bg-white dark:bg-darkBlue text-black dark:text-white">
          <RouterLink to="/" class="swingi-logo">
            <img src="/swingi.png" alt="Swingi logo" />
          </RouterLink>

          <div class="contents">

            <div class="hamburg text-black dark:text-white">
              <DarkModeToggle />
            </div>
            
            
            <div class="userBtn" >
              <div class="moneyBtn" @click="handleClick2">
                <div v-if="!isGettingSP" class="moneyBox" >
                  {{ sPoint }}
                </div>
                <div v-else class="moneyBox" >
                  <Loading type="submit" :width="'20px'"/>
                </div>
                <div>
                  <svg fill="#ff5555" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    
                    <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                    
                    </svg>
                </div>
                
              </div>
    
              
              <div class="usrBox">
                <button @click="handleClick" :class="[isHovering ? 'hover-a-class' : 'normal-h-class']"
                @mouseover="isHovering = true"
                @mouseleave="isHovering = false">
                  <div :style="{display: 'flex', alignItems: 'center', height: '100%'}">
                    
                      <img :src="proImage" alt="User Icon" class="profile" />
                    
                    
                    <h4 :class="{
                      'text-white font-bold': isHovering,
                      'text-xs': true,
                      'max-w-150px': true,
                      'overflow-hidden': true,
                      'text-ellipsis': true,
                      'whitespace-nowrap': true,
                    }" style="padding: 5px;">
      
                  {{ user }}
                </h4>
              </div>
          
        </button>
              </div>
              <div @click="toggleSidebar" class="hamburg" >
                <svg class="dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#000000" fill="none">
                  <path d="M4 12H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                  <path d="M20 6.99972L7.00316 7C5.18197 7 4.27137 7 4.04476 6.38268C3.81814 5.76537 4.46203 5.03884 5.7498 3.58579L6.26896 3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                  <path d="M4 17.0003L16.9968 17C18.818 17 19.7286 17 19.9552 17.6173C20.1819 18.2346 19.538 18.9612 18.2502 20.4142L17.731 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              </svg>
              </div>

              

              
            </div>


          </div>
          
          
          
        </nav>
        <nav v-else class="navbar-contents">
          <RouterLink to="/" class="swingi-logo">
            <img src="/swingi.png" alt="Swingi logo" />
          </RouterLink>
          <div class=" text-black dark:text-white">
            <DarkModeToggle />
          </div>
          <div class="usrBox">
            <button @click="handleClick" :class="[isHovering ? 'hover-a-class' : 'normal-h-class']"
              @mouseover="isHovering = true"
              @mouseleave="isHovering = false">
                <div class="flex items-center">
                                  
                  <h4 :class="{
                    'text-white font-bold': isHovering,
                    'text-xs': true,
                    'max-w-150px': true,
                    'overflow-hidden': true,
                    'text-ellipsis': true,
                    'whitespace-nowrap': true,
                  }" style="padding: 5px;">

                Login | Signin
              </h4>
          </div>
      
    </button>
          </div>
        </nav>
      </header>
      <div v-if="isMenuVisible" class="sidebar">
        <div @click="handleClickOutside" class="exit">
          <svg fill="#ff5555" width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M697.4 759.2l61.8-61.8L573.8 512l185.4-185.4-61.8-61.8L512 450.2 326.6 264.8l-61.8 61.8L450.2 512 264.8 697.4l61.8 61.8L512 573.8z"/></svg>
        </div>
        <ul>
          <li>
            <button @click="disconnect">
              <div :style="{width: '90%'}">
                Log out
              </div>

              <div :style="{width: '10%'}">
                <svg :style="{color: '#ff5555'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
                  <path d="M11 3L10.3374 3.23384C7.75867 4.144 6.46928 4.59908 5.73464 5.63742C5 6.67576 5 8.0431 5 10.7778V13.2222C5 15.9569 5 17.3242 5.73464 18.3626C6.46928 19.4009 7.75867 19.856 10.3374 20.7662L11 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                  <path d="M21 12L11 12M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              </div>
              
              
            </button>
            
              

            </li>
            <li>
                <button >

                    <div>
                      Sell my Swingi Point
                      <div :style="{fontSize: '1.2vh', alignContent: 'center', fontFamily: 'Playwrite CU'}">
                      Coming Soon
                    </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ff5555" fill="none">
                      <path d="M20.5 13.5C20.5 18.1944 16.6944 22 12 22C7.30558 22 3.5 18.1944 3.5 13.5C3.5 8.80558 7.30558 5 12 5C16.6944 5 20.5 8.80558 20.5 13.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M12 19C8.96243 19 6.5 16.5376 6.5 13.5C6.5 10.4624 8.96243 8 12 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M13.5 3.5C13.5 2.67157 12.8284 2 12 2C11.1716 2 10.5 2.67157 10.5 3.5C10.5 4.32843 11.1716 5 12 5C12.8284 5 13.5 4.32843 13.5 3.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M12 13.5L15 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
                         

            </li>
            
            <li>
              <button>
                <div>
                  Store
                  <div :style="{fontSize: '1.2vh', alignContent: 'center', fontFamily: 'Playwrite CU'}">
                  Coming Soon
                </div>
                </div>
                
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ff5555" fill="none">
                    <path d="M3.00003 10.9871V15.4925C3.00003 18.3243 3.00003 19.7403 3.87871 20.62C4.75739 21.4998 6.1716 21.4998 9.00003 21.4998H15C17.8284 21.4998 19.2426 21.4998 20.1213 20.62C21 19.7403 21 18.3243 21 15.4925V10.9871" stroke="currentColor" stroke-width="1.5" />
                    <path d="M15 16.9768C14.3159 17.584 13.2268 17.9768 12 17.9768C10.7732 17.9768 9.68412 17.584 9.00003 16.9768" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M17.7957 2.50294L6.14986 2.53202C4.41169 2.44248 3.96603 3.78259 3.96603 4.43768C3.96603 5.02359 3.89058 5.87774 2.82527 7.4831C1.75996 9.08846 1.84001 9.56536 2.44074 10.6767C2.93931 11.5991 4.20744 11.9594 4.86865 12.02C6.96886 12.0678 7.99068 10.2517 7.99068 8.97523C9.03254 12.1825 11.9956 12.1825 13.3158 11.8157C14.6386 11.4483 15.7717 10.1331 16.0391 8.97523C16.195 10.4142 16.6682 11.2538 18.0663 11.8308C19.5145 12.4284 20.7599 11.515 21.3848 10.9294C22.0097 10.3439 22.4107 9.04401 21.2968 7.6153C20.5286 6.63001 20.2084 5.7018 20.1033 4.73977C20.0423 4.18234 19.9888 3.58336 19.5972 3.20219C19.0248 2.64515 18.2036 2.47613 17.7957 2.50294Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              
              </button>
              

            

          </li>

            <li>
                <button >
                  <div :style="{width: '90%'}">
                    News
                  </div>
                  
                  <div :style="{width: '10%'}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ff5555" fill="none">
                      <path d="M18 15V9C18 6.17157 18 4.75736 17.1213 3.87868C16.2426 3 14.8284 3 12 3H8C5.17157 3 3.75736 3 2.87868 3.87868C2 4.75736 2 6.17157 2 9V15C2 17.8284 2 19.2426 2.87868 20.1213C3.75736 21 5.17157 21 8 21H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6 8L14 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6 12L14 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6 16L10 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M18 8H19C20.4142 8 21.1213 8 21.5607 8.43934C22 8.87868 22 9.58579 22 11V19C22 20.1046 21.1046 21 20 21C18.8954 21 18 20.1046 18 19V8Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  </div>
                </button>

              
  
            </li>

            <li>
                <a href="/ReportBugs">
                  <div :style="{width: '90%'}">
                    Report Bug
                  </div>
                  
                  <div :style="{width: '10%'}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ff5555" fill="none">
                      <path d="M3.01206 6.59744C2.89214 7.65674 3.6584 10.1951 5.87693 10.255" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M18.2891 10.195C19.3684 10.255 20.9873 8.99582 20.9873 6.59741" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M20.0876 20.9879C20.1476 19.6088 19.2482 17.4742 17.5093 17.4142" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M3.90138 20.9881C3.84009 19.5998 4.7594 17.4509 6.53675 17.3905" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M3.01227 13.7926H5.21757" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M21 13.7926L18.8282 13.7926" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M11.9932 13.7926V10.8546M16.4902 2.99982L14.6914 4.79862M7.49615 2.99982L9.29496 4.79862" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M6.95612 8.61209C8.57504 9.77532 12.5924 11.4542 16.9695 8.68404" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M16.826 7.6767C13.2523 2.7 8.69536 5.09841 7.07644 7.8206C6.02093 9.59542 4.37823 13.8886 7.49616 18.5655C10.8539 22.6667 14.8113 20.808 16.4302 18.6494C17.9892 16.7906 19.5242 11.9938 16.826 7.6767Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                  </svg>
                  </div>
                </a>
              
  
            </li>
            
          
        </ul>
      </div>
</template>

<script>
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Loading from '../components/items/loading.vue';
import DarkModeToggle from '../components/module/DarkModeToggle.vue';


export default {
    name: "HeaderNavbar",

    components: {
      Loading,
      DarkModeToggle,
  },
  
    
    props: {
    userId: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    },
    swingiPoint: {
      type: String,
      required: false
    }
    
  },


  data() {
    return {
      isGettingSP: true,
      isHovering: false,
      hamIsHovering: false,
      token: '',
      user: 'Start',
      proImage: '',
      isMenuVisible: false,
      sPoint: '0',
      sCoin: '0',

    };
  },
  created() {
    
      //token: localStorage.getItem('authToken');
      //email: localStorage.getItem('userEmail');
      if(localStorage.getItem('authToken') != null){
        
        this.token = localStorage.getItem('authToken');
        if(localStorage.getItem('userName')) {
          this.user = localStorage.getItem('userName');
        } else {
          this.user = this.token;
        }
        if(localStorage.getItem('proImage')) {
          this.proImage = localStorage.getItem('proImage');
          console.log('image found : ', this.proImage);
        } else {
          this.proImage = '/g.png';
          console.log('image not found.');
        }


      this.getUserBank();


      
      } else {
        this.proImage = '/g.png';
          console.log('image not found.');
      }
      
      
    
  },

  mounted() {
    this.searchUserImage();
  },

  

  methods: {
      handleClick() {
      // Redirection vers la page de login ou utilisateur
      this.$router.push({ name: 'UserD', params: { id: this.userId } });
    },

    handleClick2() {
      console.log('Button clicked');
      // Ajoutez votre logique ici
    },

    toggleSidebar() {
      this.isMenuVisible = !this.isMenuVisible; // Toggle visibility
    },
    handleClickOutside() {
      this.isMenuVisible = false; // Close sidebar on outside click
    },

    wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async revealEffect() {
          const characters = '0123456789';
            let randomText = '';
            
            const currentText = this.sPoint;

            
              for (let i = 0; i < currentText.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText + randomL;
                await this.wait(40);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText;
                this.sPoint = randomText + randomL2;
                await this.wait(40);
                randomText += currentText[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.sPoint = randomText;

            }


            
        },


    async getUserBank(){
      this.isGettingSP = true;
      try {
        const response = await fetch('https://swingi.pro/api.php?action=getBankDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        
        console.log('found Token Index : ', info.data[foundTokenIndex].sPoint);  

        if (foundTokenIndex !== -1) {

          
          this.sPoint = info.data[foundTokenIndex].sPoint;
          this.sCoin = info.data[foundTokenIndex].sCoin;
          localStorage.setItem('sPoint', this.sPoint);
          this.revealEffect();
          
          
        } else {
          console.log('No Bank Yet');
        }
        this.isGettingSP = false;
      }catch (error){
        this.isGettingSP = false;
        console.error(error);
      }
        
    },

    

    disconnect() {
      
      localStorage.removeItem('authToken');
      localStorage.removeItem('proImage');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('instagramUser');
          localStorage.removeItem('instaUser');
          localStorage.removeItem('instaLinkHD');
          localStorage.removeItem('country');
          localStorage.removeItem('qst');
          localStorage.removeItem('answer');
          localStorage.removeItem('statisticsID');
          localStorage.removeItem('dayTime');
      console.log('disconnected');
      
      window.location.href = this.$router.resolve({ name: 'Home' }).href;
    },

    autoSignIn() {
    const userData = this.loadUserData();
    if (userData.token && userData.email && userData.password) {
      signInWithEmailAndPassword(auth, userData.email, userData.password)
        .then((userCredential) => {
          console.log('User signed in automatically:', userCredential.user);
          this.$router.push({ name: 'Home' });
        })
        .catch((error) => {
          console.error('Error signing in automatically:', error.message);
        });
    } else {
      console.error('No user acount found, pleas login/register');
    }
  },

  async searchUserImage(){

    if (localStorage.getItem('authToken')) {

      const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        console.log('found Token : ', info.data[foundTokenIndex].profileImage);
        

        if (this.foundTokenIndex !== -1) {
          
          if (info.data[foundTokenIndex].userName !== '' && info.data[foundTokenIndex].userName !== null) {
            this.user = info.data[foundTokenIndex].userName;
            this.proImage = info.data[foundTokenIndex].profileImage;
            localStorage.setItem('proImage', this.proImage);
          } else {
            this.user = info.data[foundTokenIndex].token;
          }
          
        }
    } else {
      this.isGettingSP = false;
      this.sPoint = 0;
    }
      
    },
    },

}
</script>

<style scoped>



.navbar-contents {
  position: fixed;
  width: 80%;
  height: 50px;
  background: rgba(231, 231, 231, 0.6);
  border: 1px solid rgba(255, 255, 255, .6);
  backdrop-filter: blur(2px);
  box-shadow: 0 0 10px #0076bb60;
  border-radius: 10px;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 10px);
  display: flex;
  justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
  align-items: center; /* Centre les éléments verticalement */
  z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

}

.dark .navbar-contents {
  position: fixed;
  width: 80%;
  height: 50px;
  background: #0d0d52be;
  border: 1px solid rgba(20, 20, 20, 0.4);
  backdrop-filter: blur(2px);
  box-shadow: 0 0 10px #00000080;
  border-radius: 10px;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 10px);
  display: flex;
  justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
  align-items: center; /* Centre les éléments verticalement */
  z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

}


.hamburg {
  width: 40px;
  height: 40px;
  backdrop-filter: blur(2px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.hamburg:hover {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  cursor: pointer;
}

.svg-icons {
  
  margin: 5px;
  width: 20px;
}

.storeImg {
  display: flex;
  margin: 5px;
  width: 20px;
  justify-content: center;
}

.actions {
  margin-top: 10px;
  width: 120px;
  display: flex;
  justify-content: center;
  font-family: 'Josefin Sans', sans-serif;
  z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

}

.exit {
  margin: 2px;
  position: fixed;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  backdrop-filter: blur(2px);
  border-radius: 10px;
}

.exit:hover {
  margin: 2px;
  position: fixed;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 80px;
  width: 250px;
  background: rgba(231, 231, 231, 0.6);
  border: 1px solid rgba(255, 255, 255, .6);
  backdrop-filter: blur(2px);
  box-shadow: 0 0 10px #0076bb60;
  border-radius: 10px;
  color: black;
  margin-inline: 10%;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.dark .sidebar {
  position: fixed;
  right: 0;
  top: 80px;
  width: 250px;
  background: #0d0d52be;
  border: 1px solid rgba(20, 20, 20, 0.4);
  backdrop-filter: blur(2px);
  box-shadow: 0 0 10px #00000080;
  border-radius: 10px;
  color: white;
  margin-inline: 10%;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.sidebar ul {
  width: 100%;
  list-style-type: none;
  padding: 0;
}


.sidebar li svg {
  vertical-align: middle; /* Aligne le SVG avec le texte */
}


.sidebar a {
    display: flex;
  align-items: center; /* Centre verticalement les éléments dans le li */
  justify-content: space-between; /* Centre horizontalement les éléments dans le li */
  width: calc(100% - 20px);
  height: 40px;
  margin: 20px 10px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2vh;
  cursor: pointer;
}

.sidebar a:hover {
  display: flex;
  align-items: center; /* Centre verticalement les éléments dans le li */
  justify-content: space-between;
  width: calc(100% - 20px);
  height: 40px;
  margin: 20px 10px;
  color: #ff5555;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2.2vh;
  font-weight: bold;
}

.swingi-logo{
  width: 120px;

}

.contents{
  display: flex;
  align-items: center;

}

.profile {
  margin-right: 5px;
  height: 35px;
  width: 35px;
  object-fit: cover;
    border-radius: 50%;
    border: 2px solid white;
}

.moneyBtn{
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgba(118, 187, 180, 0.2);
  border: 2px solid rgba(255, 255, 255, .9);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  display: flex;
  justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
  align-items: center; /* Centre les éléments verticalement */
  font-size: 14px;
  padding-right: 5px;
  padding-left: 5px;
  max-width: 35%;
  height: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}



.moneyBtn:hover {
  background-color: #ff5555;
  color: #fff;
  transform: scale(1.05);
}

.moneyBox{
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.moneyBtn svg {
  fill: currentColor;
  stroke: currentColor;
}


.bankBtn{
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  max-width: 100px;
}
.normal-h-class {
    padding: 2px;
    max-width: 150px;
    height: 2.5rem; /* h-20 equivalent */
    background-color: transparent;
}

.usrBox{
  height: 40px;
  padding-right: 5px;
  max-width: 150px;
  min-width: 68px;
  font-family: 'Josefin Sans', sans-serif;/*Playwrite CU*/
  font-weight: bold;
}

.userBtn {

  display: flex;
  justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
  align-items: center; /* Centre les éléments verticalement */

}

.hover-a-class {
  border-radius: 8px;
  max-width: 150px;
  padding: 2px;
    height: 2.5rem; /* h-10 equivalent */
    background: linear-gradient(45deg, #fd1d1d60, #fcb04560, #833ab460, #fd1d1d60);
    background-size: 300% 300%;
    animation: gradientAnimation 5s ease infinite;
}

li button {
  all: unset;
  display: flex;
  align-items: center; /* Centre verticalement les éléments dans le li */
  justify-content: space-between; /* Centre horizontalement les éléments dans le li */
  width: calc(100% - 20px);
  height: 40px;
  margin: 20px 10px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2vh;
  cursor: pointer;
}

li button:hover {
  all: unset;
  display: flex;
  align-items: center; /* Centre verticalement les éléments dans le li */
  justify-content: space-between;
  width: calc(100% - 20px);
  height: 40px;
  margin: 20px 10px;
  color: #ff5555;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2.2vh;
  font-weight: bold;
  cursor: pointer;
}


@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}
</style>