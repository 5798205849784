<template>
    <header>
      <nav v-if="token" class="mobile-navbar-contents">
        
            <div class="swingi-logo">
                <RouterLink to="/">
                    <img src="/swingi.png" alt="Swingi logo" />
                  </RouterLink>
            </div>
            
            <div class="userInfo">
                <button @click="handleClick">
                  <div class="flex items-center">
                    <img :src="proImage" alt="User Icon" class="mobile-profile" />
                    <h4 :class="{
                      'text-xs': true,
                      'max-w-150px': true,
                      'overflow-hidden': true,
                      'text-ellipsis': true,
                      'whitespace-nowrap': true,
                    }" style="padding: 5px;">
                    {{ user }}
                  </h4>
                  </div>
                </button>
              </div>
        
        
        
        
              
              <div class="hamburg">
                <div v-if="isVisible1" :class="['actions', { animate: hasStarted1 }]">
                  
                  <div class=" text-black dark:text-textWhite">
                    <DarkModeToggle />
                  </div>
                    
                  
                </div>
              </div>
            
              
          

            <div class="moneyBtn" @click="handleClick2">
                <div v-if="!isGettingSP" class="moneyBox" >
                  {{ sPoint }}
                </div>
                <div v-else class="moneyBox" >
                  <Loading type="submit" :width="'20px'"/>
                </div>
                <div>
                  <svg fill="#ff5555" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    
                    <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                    
                    </svg>
                </div>
                
              </div>

              <button v-if="!vueMore" @click="showMore" class="hamburg">
                <div v-if="isVisible2" :class="['actions2', { animate: hasStarted2 }]" >
                  <svg class=" dark:text-textWhite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="black" fill="none">
                    <rect x="10.5" y="3" width="3" height="3" rx="1" stroke="currentColor" stroke-width="1.5" />
                    <rect x="10.5" y="10.5" width="3" height="3" rx="1" stroke="currentColor" stroke-width="1.5" />
                    <rect x="10.5" y="18" width="3" height="3" rx="1" stroke="currentColor" stroke-width="1.5" />
                </svg>
                  More
                </div>
              </button>

              

              <div v-if="vueMore">

                <RouterLink to="" class="hamburg">
                  <div v-if="isVisible2" :class="['actions2', { animate: hasStarted2 }]">
                    
                      <svg class=" dark:text-textWhite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="black" fill="none">
                        <path d="M3.00003 10.9871V15.4925C3.00003 18.3243 3.00003 19.7403 3.87871 20.62C4.75739 21.4998 6.1716 21.4998 9.00003 21.4998H15C17.8284 21.4998 19.2426 21.4998 20.1213 20.62C21 19.7403 21 18.3243 21 15.4925V10.9871" stroke="currentColor" stroke-width="1.5" />
                        <path d="M15 16.9768C14.3159 17.584 13.2268 17.9768 12 17.9768C10.7732 17.9768 9.68412 17.584 9.00003 16.9768" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M17.7957 2.50294L6.14986 2.53202C4.41169 2.44248 3.96603 3.78259 3.96603 4.43768C3.96603 5.02359 3.89058 5.87774 2.82527 7.4831C1.75996 9.08846 1.84001 9.56536 2.44074 10.6767C2.93931 11.5991 4.20744 11.9594 4.86865 12.02C6.96886 12.0678 7.99068 10.2517 7.99068 8.97523C9.03254 12.1825 11.9956 12.1825 13.3158 11.8157C14.6386 11.4483 15.7717 10.1331 16.0391 8.97523C16.195 10.4142 16.6682 11.2538 18.0663 11.8308C19.5145 12.4284 20.7599 11.515 21.3848 10.9294C22.0097 10.3439 22.4107 9.04401 21.2968 7.6153C20.5286 6.63001 20.2084 5.7018 20.1033 4.73977C20.0423 4.18234 19.9888 3.58336 19.5972 3.20219C19.0248 2.64515 18.2036 2.47613 17.7957 2.50294Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                      
                    
                    Soon
                  </div>
                </RouterLink>
                
                <div v-if="isVisible3" class="hamburg">
                  
                  <button @click="disconnect" :class="['actions3', { animate: hasStarted3 }]">
                      <svg class=" dark:text-textWhite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="black" fill="none">
                        <path d="M15 17.625C14.9264 19.4769 13.3831 21.0494 11.3156 20.9988C10.8346 20.987 10.2401 20.8194 9.05112 20.484C6.18961 19.6768 3.70555 18.3203 3.10956 15.2815C3 14.723 3 14.0944 3 12.8373L3 11.1627C3 9.90561 3 9.27705 3.10956 8.71846C3.70555 5.67965 6.18961 4.32316 9.05112 3.51603C10.2401 3.18064 10.8346 3.01295 11.3156 3.00119C13.3831 2.95061 14.9264 4.52307 15 6.37501" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M21 12H10M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    Log out
                  </button>
                </div>
                <div v-if="isVisible4" class="hamburg">
                  <RouterLink to="/News" :class="['actions4', { animate: hasStarted4 }]">
                    <svg class=" dark:text-textWhite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="black" fill="none">
                      <path d="M18 15V9C18 6.17157 18 4.75736 17.1213 3.87868C16.2426 3 14.8284 3 12 3H8C5.17157 3 3.75736 3 2.87868 3.87868C2 4.75736 2 6.17157 2 9V15C2 17.8284 2 19.2426 2.87868 20.1213C3.75736 21 5.17157 21 8 21H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6 8L14 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6 12L14 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6 16L10 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M18 8H19C20.4142 8 21.1213 8 21.5607 8.43934C22 8.87868 22 9.58579 22 11V19C22 20.1046 21.1046 21 20 21C18.8954 21 18 20.1046 18 19V8Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  News
                </RouterLink>
                </div>
                <div v-if="isVisible5" class="hamburg">
                  <RouterLink to="/ReportBugs" :class="['actions5', { animate: hasStarted5 }]">
                    <svg class=" dark:text-textWhite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="black" fill="none">
                      <path d="M18 6C17.9531 4.44655 17.7797 3.51998 17.1377 2.87868C16.2581 2 14.8423 2 12.0108 2H8.0065C5.17501 2 3.75926 2 2.87963 2.87868C2 3.75736 2 5.17157 2 8V16C2 18.8284 2 20.2426 2.87963 21.1213C3.75926 22 5.17501 22 8.0065 22H12.0108C14.8423 22 16.2581 22 17.1377 21.1213C17.7797 20.48 17.9531 19.5535 18 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M20.2419 11.7419L21.419 10.5648C21.6894 10.2944 21.8246 10.1592 21.8969 10.0134C22.0344 9.73584 22.0344 9.41003 21.8969 9.13252C21.8246 8.98666 21.6894 8.85145 21.419 8.58104C21.1485 8.31062 21.0133 8.17542 20.8675 8.10314C20.59 7.96562 20.2642 7.96562 19.9866 8.10314C19.8408 8.17542 19.7056 8.31062 19.4352 8.58104L18.2581 9.7581M20.2419 11.7419L14.9757 17.0081L12 18L12.9919 15.0243L18.2581 9.7581M20.2419 11.7419L18.2581 9.7581" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M5 19H6L7.25 16.5L8.5 19H9.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6 6H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M6 10H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Bug
                </RouterLink>
                </div>
                <div v-if="isVisible6" class="hamburg">
                  <button @click="showLess" :class="['actions6', { animate: hasStarted6 }]">
                    <svg class=" dark:text-textWhite" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="black" fill="none">
                      <path d="M14 8L10.6678 11.0203C9.77741 11.8273 9.77741 12.1727 10.6678 12.9797L14 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                  </svg>
                  Less
                </button>
                </div>
              </div>
        
              

      </nav>
      <nav v-else class="mobile-navbar-contents">
          <div class="swingi-logo">
              <RouterLink to="/">
                  <img src="/swingi.png" alt="Swingi logo" />
                </RouterLink>
          </div>
          
          <div class="userInfo">
              <button @click="handleClick">
                <div class="flex items-center">
                  
                  <h4 :class="{
                    'text-xs': true,
                    'max-w-150px': true,
                    'overflow-hidden': true,
                    'text-ellipsis': true,
                    'whitespace-nowrap': true,
                  }" style="padding: 5px;">
                  Login | Signin
                </h4>
                </div>
              </button>
            </div>
      </nav>
    </header>
  </template>
  
  <script>
  import Loading from '../components/items/loading.vue';
  import DarkModeToggle from '../components/module/DarkModeToggle.vue';

  export default {
    name: "MobileNavbar",

    components: {
      Loading,
      DarkModeToggle
  },
  
    props: {
      userId: {
        type: String,
        required: true
      },
      username: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        isGettingSP: true,
        user: 'start',
        proImage: localStorage.getItem('proImage') ? localStorage.getItem('proImage') : '/g.png',
        sPoint: 0,
        vueMore: false,
        isVisible1: false,
        isVisible2: false,
        isVisible3: false,
        isVisible4: false,
        isVisible5: false,
        isVisible6: false,
        hasStarted1: false,
        hasStarted2: false,
        hasStarted3: false,
        hasStarted4: false,
        hasStarted5: false,
        hasStarted6: false,
      };
    },

    created() {
    
    //token: localStorage.getItem('authToken');
    //email: localStorage.getItem('userEmail');
    if(localStorage.getItem('authToken') != null){
      
      this.token = localStorage.getItem('authToken');
      if(localStorage.getItem('userName')) {
        this.user = localStorage.getItem('userName');
      } else {
        this.user = this.token;
      }
      if(localStorage.getItem('proImage')) {
        this.proImage = localStorage.getItem('proImage');
      } else {
        this.proImage = '/g.png';
      }

      setTimeout(() => {
        this.getUserBank();
      }, 5000);

    
    }  
  
    },


    mounted() {
      this.searchUserImage();
      this.activateAnimation();

    
  },
  
    methods: {
      activateAnimation () {
        this.isVisible1 = false,
        this.isVisible2 = false,
        this.isVisible3 = false,
        this.isVisible4 = false,
        this.isVisible5 = false,
        this.isVisible6 = false,
        this.hasStarted1 = false,
        this.hasStarted2 = false,
        this.hasStarted3 = false,
        this.hasStarted4 = false,
        this.hasStarted5 = false,
        this.hasStarted6 = false,
        // Attendez un délai avant d'afficher l'élément et lancer l'animation
        setTimeout(() => {
          this.isVisible1 = true; // Affiche l'élément
          this.isVisible2 = true; // Affiche l'élément
          this.isVisible3 = true; // Affiche l'élément
          this.isVisible4 = true; // Affiche l'élément
          this.isVisible5 = true; // Affiche l'élément
          this.isVisible6 = true; // Affiche l'élément
          setTimeout(() => {
            this.hasStarted1 = true; // Démarre l'animation
            this.hasStarted2 = true; // Démarre l'animation
            this.hasStarted3 = true; // Démarre l'animation
            this.hasStarted4 = true; // Démarre l'animation
            this.hasStarted5 = true; // Démarre l'animation
            this.hasStarted6 = true; // Démarre l'animation
          }, 50); // Petit délai pour garantir le rendu avant l'animation
        }, 100); // Temps avant d'afficher et d'animer l'élément

      },
      showMore() {
        this.vueMore = true;
        this.activateAnimation();
      },

      showLess() {
        this.vueMore = false;
        this.activateAnimation();
      },

      handleClick() {
        this.$router.push({ name: 'UserD', params: { id: this.userId } });
      },
      disconnect() {
        localStorage.clear();
        window.location.href = this.$router.resolve({ name: 'Home' }).href;
      },

      wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async revealEffect() {
          const characters = '0123456789';
            let randomText = '';
            
            const currentText = this.sPoint;

            
              for (let i = 0; i < currentText.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText + randomL;
                await this.wait(40);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText;
                this.sPoint = randomText + randomL2;
                await this.wait(40);
                randomText += currentText[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.sPoint = randomText;

            }


            
        },

      async getUserBank(){
        this.isGettingSP = true;
        try {
      const response = await fetch('https://swingi.pro/api.php?action=getBankDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        
        console.log('found Token Index : ', info.data[foundTokenIndex].sPoint);  

        if (foundTokenIndex !== -1) {

          
          this.sPoint = info.data[foundTokenIndex].sPoint;
          this.sCoin = info.data[foundTokenIndex].sCoin;
          localStorage.setItem('sPoint', this.sPoint);
          this.revealEffect();
          
          
        } else {
          console.log('No Bank Yet');
        }
        this.isGettingSP = false;
      }catch (error){
        this.isGettingSP = false;
        console.error(error);
      }
        
    },

    async searchUserImage(){
      if (localStorage.getItem('authToken')) {
      const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        

        if (this.foundTokenIndex !== -1) {
          
          if (info.data[foundTokenIndex].userName !== '' && info.data[foundTokenIndex].userName !== null) {
            this.user = info.data[foundTokenIndex].userName;
            this.proImage = info.data[foundTokenIndex].profileImage;
            localStorage.setItem('proImage', this.proImage);
          } else {
            this.user = info.data[foundTokenIndex].token;
          }
          
        }
      } else {
      this.isGettingSP = false;
      this.sPoint = 0;
    }
  }
  }
  };
  </script>
  
  <style scoped>
  .mobile-navbar-contents {
    position: fixed;
    width: 100%;
    top: 10px;
    z-index: 10000;
  }

  .swingi-logo{
    position: fixed;
    top: 10px;
    left: 0px;
    padding: 2px;
    width: 120px;
    height: 45px;
    margin-inline: 10px;
    background: rgba(231, 231, 231, 0.6);
    border: 1px solid rgba(255, 255, 255, .6);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #0076bb60;
    border-radius: 10px;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

  }

  .dark .swingi-logo{
    position: fixed;
    top: 10px;
    left: 0px;
    padding: 2px;
    width: 120px;
    height: 45px;
    margin-inline: 10px;
    background: #0d0d52be;
    border: 1px solid rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

  }


  
  .mobile-profile {
    top: 10px;
    right: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
    
  }
  
  .userInfo {
    position: fixed;
    top: 10px;
    right: 0px;
    display: flex;
    align-items: center;
    background: rgba(231, 231, 231, 0.6);
    border: 1px solid rgba(255, 255, 255, .6);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #0076bb60;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    padding-inline: 5px;
    margin-inline: 10px;
    max-width: 150px;
    min-width: 68px;
    height: 45px;
    font-family: 'Josefin Sans', sans-serif;/*Playwrite CU*/
    font-weight: bold;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

  }

  .dark .userInfo {
    position: fixed;
    top: 10px;
    right: 0px;
    display: flex;
    align-items: center;
    background: #0d0d52be;
    border: 1px solid rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    padding-inline: 5px;
    margin-inline: 10px;
    max-width: 150px;
    min-width: 68px;
    height: 45px;
    font-family: 'Josefin Sans', sans-serif;/*Playwrite CU*/
    font-weight: bold;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

  }

  .svg-icons {
    margin-right: 5px;
    margin-left: 5px;
    width: 20px;
  }

  .storeImg {
  
    margin: 5px;
    width: 20px;
  }

  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%); /* L'élément commence en dehors de l'écran à gauche */
      opacity: 0; /* Optionnel : effet de fondu */
    }
    to {
      transform: translateX(0); /* L'élément arrive à sa position normale */
      opacity: 1; /* Optionnel : effet de fondu */
    }
  }
  
  .actions {
    position: fixed;
    top: 55px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(231, 231, 231, 0.6);
    border: 1px solid rgba(255, 255, 255, .6);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #0076bb60;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }

  .dark .actions {
    position: fixed;
    top: 55px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0d0d52be;
    border: 1px solid rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }
  
  .actions.animate {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    animation: slideInFromLeft 0.2s ease-out 0.3s;
  }
  .actions2 {
    position: fixed;
    top: 100px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(231, 231, 231, 0.6);
    border: 1px solid rgba(255, 255, 255, .6);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #0076bb60;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }

  .dark .actions2 {
    position: fixed;
    top: 100px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0d0d52be;
    border: 1px solid rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }
  
  .actions2.animate {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    animation: slideInFromLeft 0.4s ease-out 0.3s;
  }

  .actions3 {
    position: fixed;
    top: 145px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(231, 231, 231, 0.6);
    border: 1px solid rgba(255, 255, 255, .6);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #0076bb60;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }

  .dark .actions3 {
    position: fixed;
    top: 145px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0d0d52be;
    border: 1px solid rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }
  
  .actions3.animate {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    animation: slideInFromLeft 0.6s ease-out 0.3s;
  }

  .actions4 {
    position: fixed;
    top: 190px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(231, 231, 231, 0.6);
    border: 1px solid rgba(255, 255, 255, .6);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #0076bb60;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }

  .dark .actions4 {
    position: fixed;
    top: 190px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0d0d52be;
    border: 1px solid rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }
  
  .actions4.animate {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    animation: slideInFromLeft 0.8s ease-out 0.3s;
  }

  .actions5 {
    position: fixed;
    top: 235px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(231, 231, 231, 0.6);
    border: 1px solid rgba(255, 255, 255, .6);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #0076bb60;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }

  .dark .actions5 {
    position: fixed;
    top: 235px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0d0d52be;
    border: 1px solid rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }
  
  .actions5.animate {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    animation: slideInFromLeft 1s ease-out 0.3s;
  }

  .actions6 {
    position: fixed;
    top: 280px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(231, 231, 231, 0.6);
    border: 1px solid rgba(255, 255, 255, .6);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #0076bb60;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }

  .dark .actions6 {
    position: fixed;
    top: 280px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0d0d52be;
    border: 1px solid rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.3vh;

    opacity: 0;
    transform: translateX(-100%);
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  }
  
  .actions6.animate {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    animation: slideInFromLeft 1.2s ease-out 0.3s;
  }



  .moneyBtn{
    position: fixed;
    top: 55px;
    right: 0px;
    margin-top: 10px;
    margin-inline: 10px;
    background: rgba(231, 231, 231, 0.6);
    border: 1px solid rgba(255, 255, 255, .6);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #0076bb60;
    border-radius: 10px;
    display: flex;
    justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */
    font-size: 14px;
    padding-right: 5px;
    padding-left: 5px;
    max-width: 35%;
    height: 30px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }

  .dark .moneyBtn {
    position: fixed;
    top: 55px;
    right: 0px;
    margin-top: 10px;
    margin-inline: 10px;
    display: flex;
    justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */
    font-size: 14px;
    padding-right: 5px;
    padding-left: 5px;
    max-width: 35%;
    height: 30px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    background: #0d0d52be;
    border: 1px solid rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #00000080;
    border-radius: 10px;

  }
  
  .moneyBtn:hover {
    background-color: #ff5555;
    color: #fff;
    transform: scale(1.05);
  }
  
  .moneyBox{
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .moneyBtn svg {
    fill: currentColor;
    stroke: currentColor;
    color: #ff5555;
  }

  .moneyBtn:hover  svg {
    fill: currentColor;
    stroke: currentColor;
    color: #fff;
  }
  </style>
  