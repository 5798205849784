<template>
    <form class="email-form" @submit.prevent="submitForm">
        <h1>Tell Us Your Problem</h1>
    
        <div class="form-group">
          <label for="email">Email Address:</label>
          <input 
            type="email" 
            id="email" 
            v-model="email" 
            required 
            placeholder="Enter your email"
          />
        </div>
    
        <div class="form-group">
          <label for="message">Describe Your Problem:</label>
          <textarea 
            id="message" 
            v-model="message" 
            rows="4" 
            required 
            placeholder="Describe your issue"
          ></textarea>
        </div>
    
        <button type="submit" class="btnEmail">
            Send
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#ff5555" fill="none">
                <path d="M12.5 18C12.5 18 18.5 13.5811 18.5 12C18.5 10.4188 12.5 6 12.5 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.50005 18C5.50005 18 11.5 13.5811 11.5 12C11.5 10.4188 5.5 6 5.5 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>

        <div v-if="log" class="form-group">
            {{ log }}
        </div>
      </form>
</template>

<script>

export default {
  data() {
    return {
      email: "",
      message: "",
      info: [],
      log: '',
    };
  },
  methods: {
    async submitForm() {
      if (this.email && this.message) {
        try {
        const response = await fetch(`https://swingi.pro/api.php?action=reportBug&email=${this.email}&message=${this.message}`);
        if (response.ok) {
          const data = await response.json();
          
          if (data.success) {  
            console.log('data.data: ', data.data);
            this.info = data.data;
            this.log = data.message;
          } else {
            console.log('data.data: ', data.message);
            this.info = data.message;
            this.log = data.message;
          }
        } else {
          console.error('response: ', response);
          return
        }

        
      } catch (error) {
        console.error(`Error: ${error.message}`);
          return

        
      }
        this.email = "";
        this.message = "";
      }
    },
  },
};

</script>

<style>
.email-form {
    margin-top: 100px;
    max-width: 500px; /* Prendre toute la largeur du parent */
    min-width: 300px;
    border-radius: 6px;
    background: rgba(242, 239, 239, 0.8);
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 10px #7fadc760;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;

  }

  .dark .email-form {
    background: #1c082d60;
    border: 1px solid rgba(20, 20, 20, 0.4);
    box-shadow: 0 0 10px #00000080;
  }

  .form-group {
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  form h1 {
    font-size: 3vh;
    font-weight: bold;
  }

input,
textarea {
    background: white;
  width: 100%;
  padding: 2px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}

.dark input,
.dark textarea {
    background: #06062C;
}

textarea {
  resize: none;
}

.btnEmail {
    width: 90%;
    height: 40px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vh;
    font-weight: bold;
    border: 1px solid #ff5555;
}


</style>