<template>
    <div class="body4">
      <div :style="{height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
        <div :style="{display: 'flex', alignItems: 'center', marginLeft: '5px'}">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#00ffe5" fill="none">
            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M10.5359 11.0075C9.71585 10.8916 7.84666 11.0834 6.93011 12.7782C6.01355 14.4729 6.9373 16.2368 7.51374 16.9069C8.08298 17.5338 9.89226 18.721 11.8114 17.5619C12.2871 17.2746 12.8797 17.0603 13.552 14.8153L13.4738 5.98145C13.3441 6.95419 14.4186 9.23575 17.478 9.5057" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
          <p class="tiktokTitle">
            TikTok
          </p>
        </div>

        <div class="forSvg" :style="{display: 'flex', fontSize: '1.5vh', fontFamily: 'Josefin Sans, sans-serif', color: '#7fadc7', fontWeight: 'bold'}">
          <div :style="{
            width: '30px', 
            display: 'flex', 
            justifyContent: 'center', 
            flexDirection: 'column', 
            marginInline: '5px', 
            paddingInline: '10px', 
            cursor: 'pointer', 
            textAlign: 'center', 
            alignItems: 'center', 
            backgroundColor: '#ff5555', 
            borderRadius: '5px'}">
            <svg :style="{color: 'white'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
              <path d="M14 3.5C17.7712 3.5 19.6569 3.5 20.8284 4.7448C22 5.98959 22 7.99306 22 12C22 16.0069 22 18.0104 20.8284 19.2552C19.6569 20.5 17.7712 20.5 14 20.5L10 20.5C6.22876 20.5 4.34315 20.5 3.17157 19.2552C2 18.0104 2 16.0069 2 12C2 7.99306 2 5.98959 3.17157 4.7448C4.34315 3.5 6.22876 3.5 10 3.5L14 3.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M5 15.5C6.60865 13.3626 10.3539 13.246 12 15.5M10.249 10.2501C10.249 11.2166 9.46552 12.0001 8.49902 12.0001C7.53253 12.0001 6.74902 11.2166 6.74902 10.2501C6.74902 9.28362 7.53253 8.50012 8.49902 8.50012C9.46552 8.50012 10.249 9.28362 10.249 10.2501Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 9.5L19 9.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 13.5H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          
          </div>
          
          <div :style="{
            width: '30px', 
            display: 'flex', 
            justifyContent: 'center', 
            flexDirection: 'column', 
            marginInline: '5px', 
            paddingInline: '10px', 
            cursor: 'pointer', 
            textAlign: 'center', 
            alignItems: 'center', 
            
            borderRadius: '5px'}"
            :class="{ hoverEffect: isHovered1 }"
            class="forBg"
              @mouseover="isHovered1 = true"
              @mouseleave="isHovered1 = false" @click="viewTikTokStat">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M21 21H10C6.70017 21 5.05025 21 4.02513 19.9749C3 18.9497 3 17.2998 3 14V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 4H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 7H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M5 20C6.07093 18.053 7.52279 13.0189 10.3063 13.0189C12.2301 13.0189 12.7283 15.4717 14.6136 15.4717C17.8572 15.4717 17.387 10 21 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
          <div :style="{
            width: '30px', 
            display: 'flex', 
            justifyContent: 'center', 
            flexDirection: 'column', 
            marginInline: '5px', 
            paddingInline: '10px', 
            cursor: 'pointer', 
            textAlign: 'center', 
            alignItems: 'center', 
           
            borderRadius: '5px'}"
            :class="{ hoverEffect: isHovered2 }"
            class="forBg"
              @mouseover="isHovered2 = true"
              @mouseleave="isHovered2 = false" @click="changeTikTok">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
            <div :style="{
              width: '30px', 
              display: 'flex', 
              justifyContent: 'center', 
              flexDirection: 'column', 
              marginInline: '5px', 
              paddingInline: '10px', 
              cursor: 'pointer', 
              textAlign: 'center', 
              alignItems: 'center', 
              
              borderRadius: '5px'}"
              :class="{ hoverEffect: isHovered3 }"
              class="forBg"
              @mouseover="isHovered3 = true"
              @mouseleave="isHovered3 = false" @click="editTikTok">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
                <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            </svg>
            
            </div>

            <div :style="{width: '30px', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginInline: '10px', paddingInline: '10px', cursor: 'pointer', textAlign: 'center', alignItems: 'center'}">
              <CheckBox label="" v-model="isChecked" @input="updateTikTokValue" :value="isChecked"/>
            
            </div>
            
            
            
            
        </div>
        
      </div>


        <div :style="{display: 'flex', justifyContent: 'center'}">
          <div class="instaViewBox" :class="{ hoverEffect3: isHoveredPro }"
          @mouseover="isHoveredPro = true"
          @mouseleave="isHoveredPro = false">
            <div class ="tikTokImage">
              
              <img :src="tikTokImage" alt="Profile Picture" class="profileImage">
            </div>
            <div class ="instaName">
  
              <p class="userTitle">
                {{this.tikTokUser}}
              </p>
  
            </div>
  
          </div>
        </div>
        
       

    </div>
</template>



<script>

import CheckBox from "../components/CheckBox.vue";

export default {
    name: "TikTokCardInfo",

    components: {
    CheckBox,
    
  },



    data() {
    return {
      tikTokUser: localStorage.getItem('tikTokUser'),
      tikTokImage: localStorage.getItem('tikTokLinkHD'),
        isTikTokCard: true,

        isTikTokConfirmed: false,
      token: localStorage.getItem('authToken'),
      isChecked: false,
      isHovered0: false,
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
      isHoveredPro: false,
      
    };
  },
  created() {
    this.addtikTokQst();
    //this.getTikTokInfo('tiktok');
  },
  methods: {

    editTikTok(){

      this.$emit('tikTok-edit');
    },

    changeTikTok(){

      this.$emit('tikTok-change');
    },

      viewTikTokStat() {

        this.$emit('tikTok-stat');
      },

    async updateTikTokValue(value) {
        this.isChecked = !value;
      var checkBox = 0;
      if(this.isChecked === true) {
        checkBox = 1;
      } else {
        checkBox = 0;
      }
      const updateData = {
        token: this.token,
        field: 'activ',
        value: checkBox,// Assignez l'URL de l'image téléchargée
        tableName: 'tiktokQst'
      };


      try {
        const response = await fetch('https://swingi.pro/api.php?action=updateMediaQstValue', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        });
        const data = await response.json();
        if (data.success) {
          console.log('successe tiktok value: ', data);
          localStorage.setItem('tiktok-Active', this.isChecked);
        } else {
          console.error('Error updating tiktok Value:', data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
      
    },

    async addtikTokQst() {

  try {
    
    const response = await fetch(`https://swingi.pro/api.php?action=getMediaQst&token=${encodeURIComponent(this.token)}&tableName=tiktokQst`, {
      method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Réponse POST 2:', data);

    if (data.error) {

      console.error('Error:', data.error);
    } else {
      if (data.data) {

        if (data.data[7] == 0){
            localStorage.setItem('tikTok-Active', false);
            
            this.isChecked = false;

          } else if (data.data[7] == 1) {
            localStorage.setItem('tikTok-Active', true);

            this.isChecked = true;

          } 
      } else {

        localStorage.setItem('tikTok-Active', false);
            
        this.isChecked = false;
      }

          
    }

    this.canGo = true;
  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }
}
  },
}




</script>

<style>


  .tiktokTitle {
    min-width: 70px;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 2.2vh; /* Ajuster la taille de la police selon vos besoins */
    font-weight: bold;
    background-image: linear-gradient(90deg, #25F4EE, #FE2C55, #25F4EE);
    font-family: 'Josefin Sans', sans-serif;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


  .statTitle {
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */
    color: #7fadc7;
    font-weight: bold;
  }

  .userTitle {
    display: flex; /* Définit un conteneur flex */
    align-items: center; /* Centre le texte verticalement */
    justify-content: center; /* Centre le texte horizontalement */
    text-align: center; /* Centrer le texte horizontalement à l'intérieur de l'élément */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */
    height: 100%; /* Assurez-vous que le conteneur a une hauteur définie */

  }

  .structure-box {
    margin-bottom: 25px;
    
  }


  .profileImage{
    height: 40px;
    width: 40px;
    border-radius: 50%; /* Rend l'image circulaire */
  }

  .viewStatBox {
    margin: 5px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .tikTokImage{
    margin-right: 5px;
    height: 40px;
    width: 40px;

  }
  .instaName{
    margin-right: 5px;
    height: 40px;
    width: 90%;

  }




  .text3 {
    width: 60%;
    font-size: 10px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */
  }


  .row-btn {
    display: flex; /* Définit un conteneur flex */
    align-items: center; /* Centre verticalement les éléments dans le conteneur */
    justify-content: space-between; /* Espace les éléments horizontalement avec de l'espace égal entre eux */
    width: 100%; /* Prendre toute la largeur du parent */

    

  }




  .boxBtn4 {
    width: 50%;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .btn4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */

  }
  
  .btn3:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }


</style>