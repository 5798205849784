<template>
    <div v-if="bannerInfo.check" :style="{
        margin: '5px',
        width: '100%',
        backgroundColor: '#7fadc7',
        borderRadius: '5px',
        
    }" @click="clicked(bannerInfo.type)">
    <div v-if="!bannerInfo.isMobile" :style="{
        width: '100%',
        height: '40px',
        minWidth: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer', 
        margin: '2px',
        
        //backgroundColor: '#7fadc740'

    }">
    <div :style="{
        display: 'flex',
        alignItems: 'center',

    }">
        <div>
            <img :style="{
                width: '30px',
                height: '30px',
                minWidth: '30px',
                border: '2px solid white',
                borderRadius: '50%',
                backgroundColor: 'white',
                objectFit: 'cover',
                marginInline: '5px',
            }" :src="bannerInfo.image ? bannerInfo.image: '/g.png'" alt="Thumbnail"/>
        </div>

        <div :class="{
              
            'text-xs': true,
            'max-w-100px': true,
            'overflow-hidden': true,
            'text-ellipsis': true,
            'whitespace-nowrap': true,
            
          }" :style="{fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '14px' }">
            {{ bannerInfo.name ? bannerInfo.name: 'userName' }}
        </div>
    </div>
    <div v-if="bannerInfo.type === 'insta'" @click="emiter('insta')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
        <svg :style="{
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            backgroundColor: '#0f061d',
            padding: '1px',

        }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
            {{ bannerInfo.type }}
        </div>
        
    </div>
    <div v-if="bannerInfo.type === 'youtube'" @click="emiter('youtube')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
    <svg :style="{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        backgroundColor: '#0f061d',
        padding: '1px',

    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
        <path d="M12 20.5C13.8097 20.5 15.5451 20.3212 17.1534 19.9934C19.1623 19.5839 20.1668 19.3791 21.0834 18.2006C22 17.0221 22 15.6693 22 12.9635V11.0365C22 8.33073 22 6.97787 21.0834 5.79937C20.1668 4.62088 19.1623 4.41613 17.1534 4.00662C15.5451 3.67877 13.8097 3.5 12 3.5C10.1903 3.5 8.45489 3.67877 6.84656 4.00662C4.83766 4.41613 3.83321 4.62088 2.9166 5.79937C2 6.97787 2 8.33073 2 11.0365V12.9635C2 15.6693 2 17.0221 2.9166 18.2006C3.83321 19.3791 4.83766 19.5839 6.84656 19.9934C8.45489 20.3212 10.1903 20.5 12 20.5Z" stroke="currentColor" stroke-width="1.5" />
        <path d="M15.9621 12.3129C15.8137 12.9187 15.0241 13.3538 13.4449 14.2241C11.7272 15.1705 10.8684 15.6438 10.1728 15.4615C9.9372 15.3997 9.7202 15.2911 9.53799 15.1438C9 14.7089 9 13.8059 9 12C9 10.1941 9 9.29112 9.53799 8.85618C9.7202 8.70886 9.9372 8.60029 10.1728 8.53854C10.8684 8.35621 11.7272 8.82945 13.4449 9.77593C15.0241 10.6462 15.8137 11.0813 15.9621 11.6871C16.0126 11.8933 16.0126 12.1067 15.9621 12.3129Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    <div v-if="bannerInfo.type === 'tiktok'" @click="emiter('tiktok')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
    <svg :style="{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        backgroundColor: '#0f061d',
        padding: '1px',

    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
        <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M10.5359 11.0075C9.71585 10.8916 7.84666 11.0834 6.93011 12.7782C6.01355 14.4729 6.9373 16.2368 7.51374 16.9069C8.08298 17.5338 9.89226 18.721 11.8114 17.5619C12.2871 17.2746 12.8797 17.0603 13.552 14.8153L13.4738 5.98145C13.3441 6.95419 14.4186 9.23575 17.478 9.5057" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    </div>
    <div v-else :style="{
        width: '100%',
        minWidth: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer', 
        margin: '2px',
        borderRadius: '5px',
        flexDirection: 'column',

    }">
    <div :style="{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'

    }">
        <div>
            <img :style="{
                width: '30px',
                height: '30px',
                minWidth: '30px',
                border: '2px solid white',
                borderRadius: '50%',
                backgroundColor: 'white',
                objectFit: 'cover',
                margin: '5px',
            }" :src="bannerInfo.image ? bannerInfo.image: '/g.png'" alt="Thumbnail"/>
        </div>

        <div :class="{
              
            'text-xs': true,
            'max-w-100px': true,
            'overflow-hidden': true,
            'text-ellipsis': true,
            'whitespace-nowrap': true,
            
          }" :style="{fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '14px' }">
            {{ bannerInfo.name ? bannerInfo.name: 'userName' }}
        </div>
    </div>
    <div v-if="bannerInfo.type === 'insta'" @click="emiter('insta')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    }">
        <svg :style="{
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            backgroundColor: '#0f061d',
            padding: '1px',

        }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    <div v-if="bannerInfo.type === 'youtube'" @click="emiter('youtube')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
    <svg :style="{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        backgroundColor: '#0f061d',
        padding: '1px',

    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
        <path d="M12 20.5C13.8097 20.5 15.5451 20.3212 17.1534 19.9934C19.1623 19.5839 20.1668 19.3791 21.0834 18.2006C22 17.0221 22 15.6693 22 12.9635V11.0365C22 8.33073 22 6.97787 21.0834 5.79937C20.1668 4.62088 19.1623 4.41613 17.1534 4.00662C15.5451 3.67877 13.8097 3.5 12 3.5C10.1903 3.5 8.45489 3.67877 6.84656 4.00662C4.83766 4.41613 3.83321 4.62088 2.9166 5.79937C2 6.97787 2 8.33073 2 11.0365V12.9635C2 15.6693 2 17.0221 2.9166 18.2006C3.83321 19.3791 4.83766 19.5839 6.84656 19.9934C8.45489 20.3212 10.1903 20.5 12 20.5Z" stroke="currentColor" stroke-width="1.5" />
        <path d="M15.9621 12.3129C15.8137 12.9187 15.0241 13.3538 13.4449 14.2241C11.7272 15.1705 10.8684 15.6438 10.1728 15.4615C9.9372 15.3997 9.7202 15.2911 9.53799 15.1438C9 14.7089 9 13.8059 9 12C9 10.1941 9 9.29112 9.53799 8.85618C9.7202 8.70886 9.9372 8.60029 10.1728 8.53854C10.8684 8.35621 11.7272 8.82945 13.4449 9.77593C15.0241 10.6462 15.8137 11.0813 15.9621 11.6871C16.0126 11.8933 16.0126 12.1067 15.9621 12.3129Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    <div v-if="bannerInfo.type === 'tiktok'" @click="emiter('tiktok')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
    <svg :style="{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        backgroundColor: '#0f061d',
        padding: '1px',

    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
        <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M10.5359 11.0075C9.71585 10.8916 7.84666 11.0834 6.93011 12.7782C6.01355 14.4729 6.9373 16.2368 7.51374 16.9069C8.08298 17.5338 9.89226 18.721 11.8114 17.5619C12.2871 17.2746 12.8797 17.0603 13.552 14.8153L13.4738 5.98145C13.3441 6.95419 14.4186 9.23575 17.478 9.5057" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    </div> 

    <!--*2end-->
</div>
    <div v-else :style="{
        backgroundColor: '#7fadc740',
        margin: '5px',
        width: '100%',


    }"  @click="clicked(bannerInfo.type)">
    <div v-if="!bannerInfo.isMobile" :style="{
        width: '100%',
        height: '40px',
        minWidth: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer', 
        margin: '2px',
        borderRadius: '5px',
        //backgroundColor: '#7fadc740'

    }">
    <div :style="{
        display: 'flex',
        alignItems: 'center',

    }">
        <div>
            <img :style="{
                width: '30px',
                height: '30px',
                minWidth: '30px',
                border: '2px solid white',
                borderRadius: '50%',
                backgroundColor: 'white',
                objectFit: 'cover',
                marginInline: '5px',
            }" :src="bannerInfo.image ? bannerInfo.image: '/g.png'" alt="Thumbnail"/>
        </div>

        <div :class="{
              
            'text-xs': true,
            'max-w-100px': true,
            'overflow-hidden': true,
            'text-ellipsis': true,
            'whitespace-nowrap': true,
            
          }" :style="{fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '14px' }">
            {{ bannerInfo.name ? bannerInfo.name: 'userName' }}
        </div>
    </div>
    <div v-if="bannerInfo.type === 'insta'" @click="emiter('insta')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
        <svg :style="{
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            backgroundColor: '#0f061d',
            padding: '1px',

        }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    <div v-if="bannerInfo.type === 'youtube'" @click="emiter('youtube')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
    <svg :style="{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        backgroundColor: '#0f061d',
        padding: '1px',

    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
        <path d="M12 20.5C13.8097 20.5 15.5451 20.3212 17.1534 19.9934C19.1623 19.5839 20.1668 19.3791 21.0834 18.2006C22 17.0221 22 15.6693 22 12.9635V11.0365C22 8.33073 22 6.97787 21.0834 5.79937C20.1668 4.62088 19.1623 4.41613 17.1534 4.00662C15.5451 3.67877 13.8097 3.5 12 3.5C10.1903 3.5 8.45489 3.67877 6.84656 4.00662C4.83766 4.41613 3.83321 4.62088 2.9166 5.79937C2 6.97787 2 8.33073 2 11.0365V12.9635C2 15.6693 2 17.0221 2.9166 18.2006C3.83321 19.3791 4.83766 19.5839 6.84656 19.9934C8.45489 20.3212 10.1903 20.5 12 20.5Z" stroke="currentColor" stroke-width="1.5" />
        <path d="M15.9621 12.3129C15.8137 12.9187 15.0241 13.3538 13.4449 14.2241C11.7272 15.1705 10.8684 15.6438 10.1728 15.4615C9.9372 15.3997 9.7202 15.2911 9.53799 15.1438C9 14.7089 9 13.8059 9 12C9 10.1941 9 9.29112 9.53799 8.85618C9.7202 8.70886 9.9372 8.60029 10.1728 8.53854C10.8684 8.35621 11.7272 8.82945 13.4449 9.77593C15.0241 10.6462 15.8137 11.0813 15.9621 11.6871C16.0126 11.8933 16.0126 12.1067 15.9621 12.3129Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    <div v-if="bannerInfo.type === 'tiktok'" @click="emiter('tiktok')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
    <svg :style="{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        backgroundColor: '#0f061d',
        padding: '1px',

    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
        <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M10.5359 11.0075C9.71585 10.8916 7.84666 11.0834 6.93011 12.7782C6.01355 14.4729 6.9373 16.2368 7.51374 16.9069C8.08298 17.5338 9.89226 18.721 11.8114 17.5619C12.2871 17.2746 12.8797 17.0603 13.552 14.8153L13.4738 5.98145C13.3441 6.95419 14.4186 9.23575 17.478 9.5057" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    </div>
    <div v-else :style="{
        width: '100%',
        minWidth: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer', 
        margin: '2px',
        borderRadius: '5px',
        flexDirection: 'column',

    }">
    <div :style="{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'

    }">
        <div>
            <img :style="{
                width: '30px',
                height: '30px',
                minWidth: '30px',
                border: '2px solid white',
                borderRadius: '50%',
                backgroundColor: 'white',
                objectFit: 'cover',
                margin: '5px',
            }" :src="bannerInfo.image ? bannerInfo.image: '/g.png'" alt="Thumbnail"/>
        </div>

        <div :class="{
              
            'text-xs': true,
            'max-w-100px': true,
            'overflow-hidden': true,
            'text-ellipsis': true,
            'whitespace-nowrap': true,
            
          }" :style="{fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '14px' }">
            {{ bannerInfo.name ? bannerInfo.name: 'userName' }}
        </div>
    </div>
    <div v-if="bannerInfo.type === 'insta'" @click="emiter('insta')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    }">
        <svg :style="{
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            backgroundColor: '#0f061d',
            padding: '1px',

        }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    <div v-if="bannerInfo.type === 'youtube'" @click="emiter('youtube')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
    <svg :style="{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        backgroundColor: '#0f061d',
        padding: '1px',

    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
        <path d="M12 20.5C13.8097 20.5 15.5451 20.3212 17.1534 19.9934C19.1623 19.5839 20.1668 19.3791 21.0834 18.2006C22 17.0221 22 15.6693 22 12.9635V11.0365C22 8.33073 22 6.97787 21.0834 5.79937C20.1668 4.62088 19.1623 4.41613 17.1534 4.00662C15.5451 3.67877 13.8097 3.5 12 3.5C10.1903 3.5 8.45489 3.67877 6.84656 4.00662C4.83766 4.41613 3.83321 4.62088 2.9166 5.79937C2 6.97787 2 8.33073 2 11.0365V12.9635C2 15.6693 2 17.0221 2.9166 18.2006C3.83321 19.3791 4.83766 19.5839 6.84656 19.9934C8.45489 20.3212 10.1903 20.5 12 20.5Z" stroke="currentColor" stroke-width="1.5" />
        <path d="M15.9621 12.3129C15.8137 12.9187 15.0241 13.3538 13.4449 14.2241C11.7272 15.1705 10.8684 15.6438 10.1728 15.4615C9.9372 15.3997 9.7202 15.2911 9.53799 15.1438C9 14.7089 9 13.8059 9 12C9 10.1941 9 9.29112 9.53799 8.85618C9.7202 8.70886 9.9372 8.60029 10.1728 8.53854C10.8684 8.35621 11.7272 8.82945 13.4449 9.77593C15.0241 10.6462 15.8137 11.0813 15.9621 11.6871C16.0126 11.8933 16.0126 12.1067 15.9621 12.3129Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    <div v-if="bannerInfo.type === 'tiktok'" @click="emiter('tiktok')" :style="{
        width: '135px',
        height: '50px',
        minWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }">
    <svg :style="{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        backgroundColor: '#0f061d',
        padding: '1px',

    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
        <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M10.5359 11.0075C9.71585 10.8916 7.84666 11.0834 6.93011 12.7782C6.01355 14.4729 6.9373 16.2368 7.51374 16.9069C8.08298 17.5338 9.89226 18.721 11.8114 17.5619C12.2871 17.2746 12.8797 17.0603 13.552 14.8153L13.4738 5.98145C13.3441 6.95419 14.4186 9.23575 17.478 9.5057" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
        <div :style="{
            width: '90px',
            color: 'white', 
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '1.5vh', 
            fontWeight: 'bold',
            textAlign: 'center',
            marginInline: '10px',
            paddingInline: '5px',
            
            backgroundColor: '#0f061d',
            borderRadius: '15px'
        }">
        {{ bannerInfo.type }}
        </div>
        
    </div>
    </div> 
</div>

        
          
  
    </template>
    
    <script>
    
  
    export default {
      name: "mediaBanner",
      components: {
      },
      props: {
        bannerInfo: {
          type: Array,
          required: false,
          validator(value) {
            // Vérifier que chaque élément du tableau est une chaîne de caractères
            return value.every(item => typeof item === 'string');
          }
      },
  
      
    },
      data() {
        return {
          isChecked: this.value,
          
        };
      },
    
  
      created() {
        console.log('isMobile: ',this.bannerInfo.isMobile);
      },
      methods: {
        
        isAccepted(value) {
            this.isChecked = !value;

        },
        emiter(type) {
            if (type === 'insta') {
                this.$emit("input", 'insta');
            } else if (type === 'youtube') {
                this.$emit("input", 'youtube');
            } else if (type === 'tiktok') {
                this.$emit("input", 'tiktok');
            }
        },

        clicked(type) {
            this.isChecked != this.isChecked;
            if (type === 'insta') {
                this.$emit("clicked", 'insta');
            } else if (type === 'youtube') {
                this.$emit("clicked", 'youtube');
            } else if (type === 'tiktok') {
                this.$emit("clicked", 'tiktok');
            }
        }

    
    
      }
    };

    </script>
    
    <style scoped>
    .test {
        color : #0f061d;
    }
    
    </style>
    