import { createRouter, createWebHistory } from 'vue-router';
import HomeFeed from "../views/HomeFeed.vue"
import EmailVerification from "../views/EmailVerification.vue"
import UserDetails from "../views/UserDetails.vue"
import InstaQst from "../views/InstaQst.vue"
import Store from "../views/Store.vue"
import ReportBugs from "../views/ReportBugs.vue"


const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeFeed,
    },
    {
        path: '/userDetails/:id',
        name: 'UserD',
        component: UserDetails,
    },
    {
        path: '/QstJob/:id',
        name: 'InstaQst',
        component: InstaQst,
    },
    {
        path: '/',
        name: 'Store',
        component: Store,
    },
    {
        path: '/EmailVerification/:code',
        name: 'EmailVerification',
        component: EmailVerification,
    },

    {
        path: '/ReportBugs',
        name: 'ReportBugs',
        component: ReportBugs,
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router