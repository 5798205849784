<template>
    <div class="body">
      <div :style="{height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
        <div :style="{display: 'flex', alignItems: 'center', marginLeft: '5px'}">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ff6767" fill="none">
            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" stroke-width="1.5" />
            <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
          <p class="instaTitle">
            Instagram
          </p>
        </div>

        <div v-if="notificationVisible" class="notification">{{ notificationMessage }}</div>
        <div v-if="errorVisible" class="notification-error">{{ errorMessage }}</div>
  
        <div :style="{display: 'flex', fontSize: '1.5vh', fontFamily: 'Josefin Sans, sans-serif', color: '#7fadc7', fontWeight: 'bold'}">
          <div :style="{
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginInline: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            alignItems: 'center',
            
            borderRadius: '5px',
          }"
          class="forBg"
          :class="{ hoverEffect: isHovered0 }"
            @mouseover="isHovered0 = true"
            @mouseleave="isHovered0 = false"
            @click="viewCard">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M14 3.5C17.7712 3.5 19.6569 3.5 20.8284 4.7448C22 5.98959 22 7.99306 22 12C22 16.0069 22 18.0104 20.8284 19.2552C19.6569 20.5 17.7712 20.5 14 20.5L10 20.5C6.22876 20.5 4.34315 20.5 3.17157 19.2552C2 18.0104 2 16.0069 2 12C2 7.99306 2 5.98959 3.17157 4.7448C4.34315 3.5 6.22876 3.5 10 3.5L14 3.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M5 15.5C6.60865 13.3626 10.3539 13.246 12 15.5M10.249 10.2501C10.249 11.2166 9.46552 12.0001 8.49902 12.0001C7.53253 12.0001 6.74902 11.2166 6.74902 10.2501C6.74902 9.28362 7.53253 8.50012 8.49902 8.50012C9.46552 8.50012 10.249 9.28362 10.249 10.2501Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 9.5L19 9.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 13.5H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          
          </div>
          
          
          <div :style="{
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginInline: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            alignItems: 'center',
            
            borderRadius: '5px',
          }"
          class="forBg"
            :class="{ hoverEffect: isHovered1 }"
            @mouseover="isHovered1 = true"
            @mouseleave="isHovered1 = false" @click="viewStat">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M21 21H10C6.70017 21 5.05025 21 4.02513 19.9749C3 18.9497 3 17.2998 3 14V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 4H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 7H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M5 20C6.07093 18.053 7.52279 13.0189 10.3063 13.0189C12.2301 13.0189 12.7283 15.4717 14.6136 15.4717C17.8572 15.4717 17.387 10 21 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
          <div :style="{
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginInline: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            alignItems: 'center',
            backgroundColor: '#ff5555',
            borderRadius: '5px',
          }"
            >
            <svg :style="{color: 'white'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
              <path d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
            <div :style="{
              width: '30px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginInline: '5px',
              paddingInline: '10px',
              cursor: 'pointer',
              textAlign: 'center',
              alignItems: 'center',
              
              borderRadius: '5px',
            }"
            class="forBg"
              :class="{ hoverEffect: isHovered3 }"
              @mouseover="isHovered3 = true"
              @mouseleave="isHovered3 = false" @click="instaEdit">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
                <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            </svg>
            
            </div>

            <div :style="{width: '30px', marginInline: '10px', paddingInline: '10px'}">
          
            </div>
            
            
            
            
        </div>
        
      </div>

          <div class="child1">

            

            
              <div class="text">
                
                <div class="input">
                  Instagrame User Name :
                  <input v-model="username" placeholder="user_name" />
                </div>
            </div>

            <div :style="{display: 'flex', alignItems: 'center', justifyContent: 'center'}">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#ff5555" fill="none">
                <path d="M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z" stroke="currentColor" stroke-width="1.5" />
                <path d="M11.992 16H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 13L12 8.99997" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div :style="{
              
              
              margin: '15px',
              padding: '2px',
              fontSize: '2vh',
              border: '2px dashed #ff5555',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Josefin Sans, sans-serif',
              fontWeight: 'bold'
            }">              
              Make sure your instagram account are public.
              </div>
            </div>
            
            <div class="showPicturBox">
              <img src="/icons/exp1.jpg" alt="User Icon" class="picture"/>
            </div>
            <div class="boxBtn">
              <button class="btn" @click="searchInsta">
                <div v-if="!searching" class="btnCtn">
                  <div class="nameBtn">
                    {{ serachInstaName }}
                  </div>
                  
                  <div class="costBtn">
                    {{ sPCost }}
                  </div>

                  <div class="svg2">
                    <svg fill="#ff5555" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                      <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                      
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                      
                      <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                      
                      </svg>
                  </div>
                </div>
                <div v-if="searching">
                  <div class="nameBtn">
                    {{ searchingLog }}
                  </div>
                </div>
                  
              </button>
          </div>
          <div class="instaView">
            <div v-if="!isError" class="boxInsta">
              
                
                <img :src="proxyImage(info.profile_pic_url_hd)" alt="Profile Picture" v-if="info.profile_pic_url_hd" ref="profileImage" class="iProfile" >
                <p class="text">{{ info.full_name }}</p>
              
            </div>
            <div v-if="isError" class="boxInsta">
              <p class="text">{{ errorMsg }}</p>
            </div>
          </div>
            

          <div v-if="!isSaving" :style="{
            marginTop: '20px',
            maxWidth: '100%',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            marginInline: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            border: '2px dashed #7fadc7',
            color: '#7fadc7'
          }" 
          :class="{hoverEffect2: isHoveredInsta}"
          @mouseover="isHoveredInsta = true"
          @mouseleave="isHoveredInsta = false" 
          @click="convertUpload">
            Save
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M12.5 18C12.5 18 18.5 13.5811 18.5 12C18.5 10.4188 12.5 6 12.5 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.50005 18C5.50005 18 11.5 13.5811 11.5 12C11.5 10.4188 5.5 6 5.5 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div v-else :style="{
          maxWidth: '100%',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          marginInline: '5px',
          paddingInline: '10px',
          textAlign: 'center',
          alignItems: 'center',
          
          borderRadius: '5px',
          color: '#7fadc7'
        }" >
        <Loading type="submit" :width="'40px'"/>
        </div>

        <div class="structure-box2">
          <div v-if="errorInputing" class="errorText">
            {{ textErrorInputing }}
          </div>
        </div>
    
            
            
      </div>
          

          
          
            
            
 

    </div>

    
</template>


<script>

import Loading from '../components/items/loading.vue';

    export default {
    name: "AddInstagrame",

    components: {
      Loading,
  },

    data() {
    return {
      username: '',
      info: 'test',
      canGo: false,
      isInstaConfirmed: false,
      instaUrl: localStorage.getItem('instaLinkHD'),
      serachInstaName: 'search',
      sPCost: 0,
      serachYoutubeName: 'search',
      sPCost2: 0,
      searching: false,
      searchingLog: 'searching',
      isError: false,
      errorMsg: '',
      isHovered0: false,
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
      isHoveredInsta: false,
      isSaving: false,
      errorInputing: false,
      textErrorInputing: 'Pleas make sur ther was no empty Value',
      notificationMessage: '',
      notificationVisible: false,
      errorVisible: false,
      errorMessage: '',
    };
  },

  created() {
    this.getArticlesCost();
  },

  methods: {

    viewCard(){
      this.$emit('instagram-card');
    },

    viewStat(){
      this.$emit('instagram-stat');
    },

    instaEdit(){
      this.$emit('instagram-edit');
    },

    proxyImage(url) {
      return `https://swingi.pro/api.php?action=proxyImage&url=${encodeURIComponent(url)}`;
    },

    async fetchImageAsFile(url) {
      try {
        const proxyUrl = this.proxyImage(url);
        console.log('Proxy URL:', proxyUrl);

        // Faites une requête Fetch
        const response = await fetch(proxyUrl);
        if (!response.ok) {
          throw new Error('Error downloading image');
        }
        const blob = await response.blob(); // Convertir la réponse en Blob
        const file = new File([blob], "profile_pic.jpg", { type: blob.type }); // Convertir le Blob en File
        return file;
      } catch (error) {
        console.error('error01',error.message);
        return null;
      }
    },

    async searchInsta() {
      this.searching = true;

      var statut = 0;
      
      const token = localStorage.getItem('authToken'); // Remplace ceci par le vrai token de l'utilisateur
      try {
        const response = await fetch(`https://swingi.pro/api.php?action=getMedia&username=${this.username}&token=${token}&media=instagram`);
        if (!response.ok) {
          statut = response.status;
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.isError = false;
        const data = await response.json();
        this.info = data.data;
        this.sPoint = this.sPoint - this.sPCost;
      } catch (error) {
        this.info = { error: `Error: ${error.message}` };
        this.isError = true;
        if (statut === 403) {
          this.errorMsg = 'You D\'ont Have Enought sPoint';
        }
        
      }

      this.searching = false;
},



    async addInsta() {


      this.textErrorInputing = 'Saving Insta Data';
      
      const userDetails = {
        token: localStorage.getItem('authToken'),
        name: this.info.full_name,
        image: this.instaUrl,
        country: "country",
        qst: "qst",
        answer: "answer",
        statisticsID: "statisticsID",
        dayTime: new Date().toISOString(),
        tableName: 'insta'
      };

      try {

        if (localStorage.getItem('authToken') != "") {
          const resSaveMedia = await fetch('https://swingi.pro/api.php?action=saveMedia', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(userDetails)
          })
          

          if (!resSaveMedia.ok) {
            console.log('Erreur:', resSaveMedia);
            return
          }
          
          const data = await resSaveMedia.json();
          
          if (data.success) {
            this.errorInputing = true;
            this.isSaving = false;
            
            this.textErrorInputing = data.message;
            
          } else {
            this.errorInputing = true;
            this.isSaving = false;
            this.textErrorInputing = data.message;
          }

        
        } else {
          this.errorInputing = true;
          this.isSaving = false;
          this.textErrorInputing = 'Pleas Log in and try again';
        }
        this.canGo2 = true;
      } catch (error) {
        this.info = { error: `Error01: ${error.message}` };
        this.errorInputing = true;
        this.isSaving = false;
        this.textErrorInputing = `Error02: ${error}`;
      }
    },

    showError(message) {
      this.errorMessage = message;
      this.errorVisible = true;

      // Masquer la notification après 3 secondes
      setTimeout(() => {
        this.errorVisible = false;
      }, 10000);
    },
    showNotification(message) {
      this.notificationMessage = message;
      this.notificationVisible = true;

      // Masquer la notification après 3 secondes
      setTimeout(() => {
        this.notificationVisible = false;
      }, 10000);
    },
    async convertUpload() {
    console.log('this.info.profile_pic_url_hd: ', this.info.profile_pic_url_hd);
      const file = await this.fetchImageAsFile(this.info.profile_pic_url_hd);
      if (!file) {
        this.showError('Erreur lors de la récupération de l\'image.');
        return;
      }

      console.log('Image téléchargée en tant que fichier :', file);

      // Vérifier la validité du fichier (par exemple, le type)
      if (!file.type.startsWith('image/')) {
        this.showError('Please select a valid image file.');
        return;
      }

    // Vérifiez que le type du fichier est une image valide
    if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
      this.showError('Please select a valid image file (JPG, PNG, or WEBP).');
      return;
    }
    console.log('test4');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('authToken'));
    formData.append('fileToUpload', file);
    formData.append('file', 'insta');

    // Si le fichier est déjà en WebP, pas besoin de conversion
    if (file.type === 'image/webp') {
      console.log('test5');
      
      this.uploadImage(formData);  // Appel direct si le fichier est déjà en WebP
      console.log('test6');
    } else {
      console.log('test7');
      // Si ce n'est pas WebP, vous pouvez convertir l'image en WebP ici.
      const reader = new FileReader();
      
      reader.onload = (e) => {  // Utilisation d'une fonction fléchée pour maintenir le contexte de `this`
      console.log('test8');
        const img = new Image();
        img.onload = () => {
          // Créer un canvas pour convertir l'image en WebP
          console.log('test9');
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // Redimensionner le canvas pour correspondre à l'image
          canvas.width = img.width;
          canvas.height = img.height;
          
          // Dessiner l'image sur le canvas
          ctx.drawImage(img, 0, 0);
          console.log('test10');
          
          // Convertir l'image en WebP
          canvas.toBlob((blob) => {
            // Ajouter le fichier WebP converti au FormData
            console.log('test11');
            formData.append('fileToUpload', blob, file.name.split('.')[0] + '.webp');
            
            // Vous pouvez maintenant envoyer le FormData à votre serveur
            this.confirmInsta(formData);  // Appel après conversion
            console.log('test12');
          }, 'image/webp', 0.8);
        };
        img.src = e.target.result;
      };
      console.log('test13');
      // Lire le fichier comme URL de données
      reader.readAsDataURL(file);
      console.log('test14');
    }
},

async confirmInsta(formData) {
  this.isSaving = true;
  this.textErrorInputing = '';

  if (this.info && this.info.full_name) {
    // Modifiez l'appel à saveInstaProfile pour accepter formData
    await this.saveInstaProfile(formData);
    await this.addInsta();

    if (this.canGo == true) {
      this.isInstaConfirmed = true;
      localStorage.setItem('instagramUser', localStorage.getItem('authToken'));
      localStorage.setItem('instaUser', this.info.full_name);
      localStorage.setItem('instaLinkHD', this.instaUrl);
      localStorage.setItem('country', "country");
      localStorage.setItem('qst', "qst");
      localStorage.setItem('answer', "answer");
      localStorage.setItem('statisticsID', "statisticsID");
      localStorage.setItem('dayTime', new Date().toISOString());
      this.$emit('instagram-confirmed');
      this.canGo2 = false;
    }
  } else {
    if (this.info) {
      if (!this.info.full_name) {
        this.errorInputing = true;
        this.isSaving = false;
        this.textErrorInputing = 'Account name not found';
      }
    } else {
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = 'Data not found';
    }
  }
},

async saveInstaProfile(formData) {
  const token = localStorage.getItem('authToken');

  if (!token) {
    this.errorInputing = true;
    this.isSaving = false;
    this.textErrorInputing = 'Token Not Found';
    return;
  }

  this.textErrorInputing = 'Uploading Insta Profile';

  for (const [key, value] of formData.entries()) {
  console.log(`${key}:`, value);
}


  try {
    const uploadResponse = await fetch('https://swingi.pro/api.php?action=uploadMediaPro', {
      method: 'POST',
      body: formData,  // Utilisez FormData ici
    });

    if (!uploadResponse.ok) {
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = 'Error in posting request';
      throw new Error('Error in posting request');
    }
    const data = await uploadResponse.json();

    if (!data.success) {
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = data.message;
      console.log('not saved: ', data.message);
    } else {
      this.errorInputing = false;
      this.isSaving = false;
      this.textErrorInputing = 'Image uploaded: ' + data.filePath;
      this.instaUrl = data.filePath;  // Utilisez l'URL de l'image sauvegardée ici
    }
  } catch (error) {
    this.errorInputing = true;
    this.isSaving = false;
    this.textErrorInputing = 'Error in posting request: ' + error.message;
    console.log('error: ', error.message);
  }
},

async getArticlesCost() {
  try {
    
    const response = await fetch(`https://swingi.pro/api.php?action=getArticlesCost`, {
      method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (data.error) {
      console.error('Erreur:', data.error, 'Détails:', data.details);
    } else {
      
      if (data.success){
        this.serachInstaName = data.data[0].name;
        this.sPCost = data.data[0].cost;
      }
    }


  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }
},





  }
}
</script>

<style>
.body {
  min-width: 300px;
  width: 90%; /* Prendre toute la largeur du parent */
  border-radius: 6px;
  background: rgba(242, 239, 239, 0.8);
  border: 1px solid rgba(255, 255, 255, .8);
  box-shadow: 0 0 10px #7fadc760;

  }

  .dark .body {
    min-width: 300px;
    width: 90%; /* Prendre toute la largeur du parent */
    border-radius: 6px;
    background: #1c082d60;
    border: 1px solid rgba(20, 20, 20, 0.4);
    box-shadow: 0 0 10px #00000080;
  
    }

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 12px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */
  }

  .showPicturBox {
    display: flex;
    justify-content: center; /* Center horizontally */

    
  }

  .picture {
    margin: 20px;
    width: 200px; /* Remplir horizontalement */
    height: 200px; /* Remplir verticalement */
  }

  .picture img {
    
    object-fit: cover; /* Maintenir les proportions et remplir complètement */
    
  }

  .iProfile {
    margin-right: 10px;
    height: 30px;
    border-radius: 50%; /* Rend l'image circulaire */
  }


  .text {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-size: 2vh; /* Ajuster la taille de la police selon vos besoins */
    font-family: 'Josefin Sans', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .child1 {
    width: 100%;
    padding: 10px;

  }

  .instaView {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .boxInsta {
    width: 100%;
    max-width: 300px;
    height: 30px;
    border: 2px solid rgb(229, 229, 229);
    border-radius: 2px;
    display: flex;
    justify-self: center;
    align-items: center;

  }

  .dark .boxInsta {

    border: 2px solid #1b1b32;


  }

  .input {
    width: 100%;
    height: 35px;
    max-width: 300px;
  }

  .input input {
    background: rgb(255, 255, 255);
  }

  .dark .input input {
    background: #06062C;
  }
  
  .input input:hover, 
  .input input:focus {
    width: 100%;
    height: 30px;
    background: rgba(255, 255, 255, .9);
    border: none;
    outline: none;
    border-radius: 2px;
    padding-left: 2px;
    font-size: 12px; /* Changez la taille de la police ici */
  }

  .dark .input input:hover, 
  .input input:focus {

    background: #171736;

  }

  .input input:not(:focus) {
    padding-left: 2px;
    font-size: 12px; /* Taille de police lorsque l'élément perd le focus */
  }
  
  .input input::placeholder {
    padding-left: 2px;
    color: gray;
    font-size: 12px; /* Changez la taille de la police du placeholder ici */
  }



  .boxBtn {
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    max-width: 300px;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
    font-size: 13px;
  }
  
  .btn:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }
  .boxBtn2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .btn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    font-size: 13px;
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
  }
  
  .btn2:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }


  .input input {
    width: 100%;
    height: 30px;
    background: rgba(212, 212, 212, 0.6);
    border: none;
    outline: none;
    border-radius: 2px;
    font-size: 16px;

  }

  .svg2 {
    width: 10%;
    min-width: 20px;
    display: flex;
    align-items: center;
  }

  .nameBtn {
  
    margin-right: 5px;
    width: 70%;
    min-width: 50px;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
  }

  .costBtn {
  
    margin-left: 5px;
    width: 20%;
    min-width: 30px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
  }

  .btnCtn {
    display: flex;
    justify-content: center;
  }

</style>