<template>
    <div class="body4">
      <div :style="{height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
        <div :style="{display: 'flex', alignItems: 'center', marginLeft: '5px'}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#FE2C55" fill="none">
                <path d="M12 20.5C13.8097 20.5 15.5451 20.3212 17.1534 19.9934C19.1623 19.5839 20.1668 19.3791 21.0834 18.2006C22 17.0221 22 15.6693 22 12.9635V11.0365C22 8.33073 22 6.97787 21.0834 5.79937C20.1668 4.62088 19.1623 4.41613 17.1534 4.00662C15.5451 3.67877 13.8097 3.5 12 3.5C10.1903 3.5 8.45489 3.67877 6.84656 4.00662C4.83766 4.41613 3.83321 4.62088 2.9166 5.79937C2 6.97787 2 8.33073 2 11.0365V12.9635C2 15.6693 2 17.0221 2.9166 18.2006C3.83321 19.3791 4.83766 19.5839 6.84656 19.9934C8.45489 20.3212 10.1903 20.5 12 20.5Z" stroke="currentColor" stroke-width="1.5" />
                <path d="M15.9621 12.3129C15.8137 12.9187 15.0241 13.3538 13.4449 14.2241C11.7272 15.1705 10.8684 15.6438 10.1728 15.4615C9.9372 15.3997 9.7202 15.2911 9.53799 15.1438C9 14.7089 9 13.8059 9 12C9 10.1941 9 9.29112 9.53799 8.85618C9.7202 8.70886 9.9372 8.60029 10.1728 8.53854C10.8684 8.35621 11.7272 8.82945 13.4449 9.77593C15.0241 10.6462 15.8137 11.0813 15.9621 11.6871C16.0126 11.8933 16.0126 12.1067 15.9621 12.3129Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            </svg>
          <p class="YoutubeTitle">
            Youtube
          </p>
        </div>

        <div class="forSvg" :style="{display: 'flex', fontSize: '1.5vh', fontFamily: 'Josefin Sans, sans-serif', color: '#7fadc7', fontWeight: 'bold'}">
          <div :style="{
            width: '30px', 
          display: 'flex', 
          justifyContent: 'center', 
          flexDirection: 'column', 
          marginInline: '5px', 
          paddingInline: '10px', 
          cursor: 'pointer', 
          textAlign: 'center', 
          alignItems: 'center', 
          backgroundColor: '#ff5555', 
          borderRadius: '5px'}"
          >
            <svg :style="{color: 'white'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
              <path d="M14 3.5C17.7712 3.5 19.6569 3.5 20.8284 4.7448C22 5.98959 22 7.99306 22 12C22 16.0069 22 18.0104 20.8284 19.2552C19.6569 20.5 17.7712 20.5 14 20.5L10 20.5C6.22876 20.5 4.34315 20.5 3.17157 19.2552C2 18.0104 2 16.0069 2 12C2 7.99306 2 5.98959 3.17157 4.7448C4.34315 3.5 6.22876 3.5 10 3.5L14 3.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M5 15.5C6.60865 13.3626 10.3539 13.246 12 15.5M10.249 10.2501C10.249 11.2166 9.46552 12.0001 8.49902 12.0001C7.53253 12.0001 6.74902 11.2166 6.74902 10.2501C6.74902 9.28362 7.53253 8.50012 8.49902 8.50012C9.46552 8.50012 10.249 9.28362 10.249 10.2501Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 9.5L19 9.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M15 13.5H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          
          </div>
          
          <div :style="{
            width: '30px', 
            display: 'flex', 
            justifyContent: 'center', 
            flexDirection: 'column', 
            marginInline: '5px', 
            paddingInline: '10px', 
            cursor: 'pointer', 
            textAlign: 'center', 
            alignItems: 'center', 
            
            borderRadius: '5px'}"
            :class="{ hoverEffect: isHovered1 }"
            class="forBg"
            @mouseover="isHovered1 = true"
            @mouseleave="isHovered1 = false" @click="statYoutube">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M21 21H10C6.70017 21 5.05025 21 4.02513 19.9749C3 18.9497 3 17.2998 3 14V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 4H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M7 7H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path d="M5 20C6.07093 18.053 7.52279 13.0189 10.3063 13.0189C12.2301 13.0189 12.7283 15.4717 14.6136 15.4717C17.8572 15.4717 17.387 10 21 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
          <div :style="{
            width: '30px', 
            display: 'flex', 
            justifyContent: 'center', 
            flexDirection: 'column',
             marginInline: '5px', 
             paddingInline: '10px', 
             cursor: 'pointer', 
             textAlign: 'center', 
             alignItems: 'center', 
             
             borderRadius: '5px'}"
             :class="{ hoverEffect: isHovered2 }"
             class="forBg"
             @mouseover="isHovered2 = true"
             @mouseleave="isHovered2 = false" @click="changeYoutube">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          
          </div>
            <div :style="{
              width: '30px', 
              display: 'flex', 
              justifyContent: 'center', 
              flexDirection: 'column', 
              marginInline: '5px', 
              paddingInline: '10px', 
              cursor: 'pointer', 
              textAlign: 'center', 
              alignItems: 'center', 
              
              borderRadius: '5px'}"
              :class="{ hoverEffect: isHovered3 }"
              class="forBg"
              @mouseover="isHovered3 = true"
              @mouseleave="isHovered3 = false" @click="editYoutube">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
                <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            </svg>
            
            </div>

            <div :style="{width: '30px', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginInline: '10px', paddingInline: '10px', cursor: 'pointer', textAlign: 'center', alignItems: 'center'}">
              <CheckBox label="" v-model="isChecked" @input="updateYoutubeValue" :value="isChecked"/>
            
            </div>
            
            
            
            
        </div>
        
      </div>


        <div :style="{display: 'flex', justifyContent: 'center'}">
          <div class="instaViewBox" :class="{ hoverEffect3: isHoveredPro }"
          @mouseover="isHoveredPro = true"
          @mouseleave="isHoveredPro = false">
            <div class ="YoutubeImage">
              
              <img :src="YoutubeImage" alt="Profile Picture" class="profileImage">
            </div>
            <div class ="instaName">
  
              <p class="userTitle">
                {{this.YoutubeUser}}
              </p>
  
            </div>
  
          </div>
        </div>
        
       

    </div>
</template>



<script>

import CheckBox from "../components/CheckBox.vue";

export default {
    name: "YoutubeCardInfo",

    components: {
    CheckBox,
    
  },



    data() {
    return {
      YoutubeUser: localStorage.getItem('YoutubeUser'),
      YoutubeImage: localStorage.getItem('YoutubeLinkHD'),
      isYoutubeConfirmed: false,
      token: localStorage.getItem('authToken'),
      isChecked: false,
      isHovered0: false,
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
      isHoveredPro: false,
      
    };
  },
  created() {
    this.addYoutubeQst();
  },
  methods: {

    


    editYoutube(){
      this.$emit('Youtube-edit');
    },

    changeYoutube(){
      this.$emit('Youtube-change');
    },

    statYoutube(){
      this.$emit('Youtube-stat');
    },

    async updateYoutubeValue(value) {

      this.isChecked = !value;
      var checkBox = 0;
      if(this.isChecked === true) {
        checkBox = 1;
      } else {
        checkBox = 0;
      }
      const updateData = {
        token: this.token,
        field: 'activ',
        value: checkBox,// Assignez l'URL de l'image téléchargée
        tableName: 'youtubeQst'
      };


      try {
        const response = await fetch('https://swingi.pro/api.php?action=updateMediaQstValue', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        });
        const data = await response.json();
        if (data.success) {
          localStorage.setItem('Youtube-Active', this.isChecked);
        } else {
          console.error('Error updating youtube Value:', data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
  
    },

    async addYoutubeQst() {

  try {
    var token = localStorage.getItem('authToken');
    const response = await fetch(`https://swingi.pro/api.php?action=getMediaQst&token=${encodeURIComponent(token)}&tableName=youtubeQst`, {
      method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Réponse POST:', data);
    if (data.error) {
      console.error('Erreur:', data.error, 'Détails:', data.details);
    } else {
      
      if (data.data) {
        if (data.data[7] == 0){
            localStorage.setItem('Youtube-Active', false);
            
            this.isChecked = false;

          } else if (data.data[7] == 1) {
            localStorage.setItem('Youtube-Active', true);

            this.isChecked = true;

          }
      } else {
            localStorage.setItem('Youtube-Active', false);
            
            this.isChecked = false;
          }

          
    }

    this.canGo = true;
  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }
}
  },
}




</script>

<style>


  .YoutubeTitle {
    min-width: 70px;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 2.2vh; /* Ajuster la taille de la police selon vos besoins */
    font-weight: bold;
    color: #FE2C55;
    font-family: 'Josefin Sans', sans-serif;
}


</style>